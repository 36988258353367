import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Outlet, Link } from "react-router-dom";
// const images = [
//   'images/gumbaja.jpg',
//   'images/gumbajap.png',
//   'images/gumbaja.jpg'
  
// ];

const Gumbajaslider = () => {






  return (
    <Carousel data-bs-theme="dark">


             {/* Slider 1 */}
      <Carousel.Item>

      <div className="carouselimage">
      <Link to="/Bestratedearbuds"> 
        <img
          src="images/buds2r1.webp"
          alt="Second slide"
        />
        </Link>
        </div>
       


        {/* <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

      

           {/* Slider 2 */}
      <Carousel.Item>
    
      <div className="carouselimage">
      <Link to="/Bestratedearbuds"> 
        <img
          src="images/noise1043.webp"
          alt="Second slide"
        />
        </Link>
        </div>
   
        {/* <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>

           {/* Slider 3 */}
      <Carousel.Item>
      <div className="carouselimage">
        <img
          src="images/gumbaja.jpg"
          alt="Third slide"
        />
        </div>
        {/* <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>

    </Carousel>
  );

};

<Outlet />

export default Gumbajaslider;
