import React from 'react';
import { Outlet, Link } from "react-router-dom";
import WhatsAppShareButton from '../component/WhatsAppShareButton';
import Footer from '../pages/Footer';
import Accordion from 'react-bootstrap/Accordion';


const Geography = () => {
  return (
<>
<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Geography: India and World</b> </h2>

<hr className="separator" />


<div className="Mainbox1">
<div className="sbox1">

<div>
  <Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='topic'>Books: Topper's Choice</h3></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <ol>
          <li>Physical Geography: <a href="https://amzn.to/3SZHjxX">Click Here</a></li>
          <li>Economic Geography: <a href="https://amzn.to/4fQTBSX">Click Here</a></li>
          <li>Human Geography: <a href="https://amzn.to/3WPEMY6">Click Here</a></li>
          <li>Indian Geography: <a href="https://amzn.to/4dv6fW1">Click Here</a></li>
          <br />
          <li>World Geography: <a href="https://amzn.to/4cRcOBr">Click Here</a></li>
          <li>World Map: <a href="https://amzn.to/4dRpxVA">Click Here</a></li>

            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
  </Accordion>
</div>
<hr className="separator" />

  <h3 className='topic'>Basics of Geography</h3>

  <ol>
  <li><Link className={"link-styles"} to="">What is Geography? Geography as a Discipline. </Link></li>
  <li><Link className={"link-styles"} to="">Important Terms (Glossary) in Geography </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Origin of the Universe </Link></li>
  <li><Link className={"link-styles"} to="">Solar System </Link></li>
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Geomorphology</h3>

<ol>
  <li><Link className={"link-styles"} to="">The Origin and Evolution of the Eath </Link></li>
  <li><Link className={"link-styles"} to="">Interior of the Earth </Link></li>
  <li><Link className={"link-styles"} to="">Distribution of Ocean and Continents </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Minerals and Rocks </Link></li>
  <li><Link className={"link-styles"} to="">Geomorphic Processes </Link></li>
  <li><Link className={"link-styles"} to="">Landforms and their Evolution </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Important Topics of Geomorphology </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>

</div>
</div>
<hr className="separator" />




{/* Next Box */}


<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Climatology</h3>

  <ol>
  <li><Link className={"link-styles"} to="">Composition and Structure of Atmosphere </Link></li>
  <li><Link className={"link-styles"} to="">Solar Radiation, Heat Balance and Temperature </Link></li>
  <li><Link className={"link-styles"} to="">Atmospheric Circulation and Weather Systems </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Water in the Atmosphere </Link></li>
  <li><Link className={"link-styles"} to="">World Climate and Climate Change </Link></li>
  <li><Link className={"link-styles"} to="">Important Topics of Climatology </Link></li>
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Oceanography</h3>

<ol>
  <li><Link className={"link-styles"} to="">Water (Oceans) </Link></li>
  <li><Link className={"link-styles"} to="">Movements of Ocean Water </Link></li>
  <li><Link className={"link-styles"} to="">Important Topics of Oceanography </Link></li>
</ol>

</div>
</div>
<hr className="separator" />




{/* Next Box */}


<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>World Geography</h3>
  <ol>
  <li><Link className={"link-styles"} to="">Seas in the World </Link></li>
  <li><Link className={"link-styles"} to="">World: Natural Vegetations </Link></li>
  <li><Link className={"link-styles"} to="">Grasslands </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Lakes and Water bodies </Link></li>
  <li><Link className={"link-styles"} to="">Straits and Trade Routes </Link></li>
  <li><Link className={"link-styles"} to="">Disputed Areas of the World </Link></li>
  <br />
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Indian Geography</h3>
<ol>
  <li><Link className={"link-styles"} to="">India- Location </Link></li>
  <li><Link className={"link-styles"} to="">Structure and Physiography of India </Link></li>
  <li><Link className={"link-styles"} to="">Drainage System of India </Link></li>

  <br />

  <li><Link className={"link-styles"} to="">Climate of India </Link></li>
  <li><Link className={"link-styles"} to="">Natural Vegetation </Link></li>
  <li><Link className={"link-styles"} to="">Soils in India </Link></li>

  <br />
  <li><Link className={"link-styles"} to="">Natural Hazards and Disasters </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>

</div>
</div>
<hr className="separator" />






{/* Next Box */}


<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Human & Economic Geography</h3>
  <ol>
  <li><Link className={"link-styles"} to="">Human Geography: Nature and Scope </Link></li>
  <li><Link className={"link-styles"} to="">The World Population Distribtion, Density and Growth </Link></li>
  <li><Link className={"link-styles"} to="">Population Composition </Link></li>

  <br />
  <li><Link className={"link-styles"} to="">Human Development </Link></li>
  <li><Link className={"link-styles"} to="">Primary Activities </Link></li>
  <li><Link className={"link-styles"} to="">Secondary Activities </Link></li>

  <br />
  <li><Link className={"link-styles"} to="">Tertiary and Quaternary Activities </Link></li>
  <li><Link className={"link-styles"} to="">Transport and Communication </Link></li>
  <li><Link className={"link-styles"} to="">International Trade </Link></li>

  <br />
  <li><Link className={"link-styles"} to="">Human Settlement </Link></li>
  <li><Link className={"link-styles"} to="">Migration: Types, Causes and Consequences </Link></li>
  <li><Link className={"link-styles"} to="">Human Development </Link></li>
</ol>
</div>













<div className="sbox1 part2">
<h3 className='topic'>Geography: Terms and Facts</h3>

<ol>
  <li><Link className={"link-styles"} to="">Places in News </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  {/* <li><Link className={"link-styles"} to="/Dummypost">Format </Link></li> */}
</ol>



</div>
</div>
<hr className="separator" />


<WhatsAppShareButton/>




<hr className="separator" />

<div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/History">History </Link></li>
          <li><Link className={"link-styles"} to="/Geography">Geography </Link></li>
          <li><Link className={"link-styles"} to="/Psir">Polity </Link></li>
          <li><Link className={"link-styles"} to="/Economy">Economy </Link></li>
          <li><Link className={"link-styles"} to="/Environment">Environment </Link></li>
          <li><Link className={"link-styles"} to="/Science">Science and Technology </Link></li>
        </ol>
      </div>

      
    <hr className="separator" />


    





    <br/>
  <Footer/>
  <Outlet />
</>
  )
}

export default Geography

