import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';




// npm install react-helmet

function Jobformat({ }) {
  return (

    <>


      <Helmet>
        <title>Job Updates by GumBaja</title>
        <meta name="description" content="Page description" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>Jobs: Latest </b></h3>

<hr className="separator" />


<div className="content-box">
        <br />
        The information provided on www.gumbaja.com is for general informational purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. <br />
        <br /><br />

<Accordion>
<br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. Important Dates: </b> <span className='clickhere' > - (Click Here)</span></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
            <ol>
              <li>Application Start:</li> <br />
              <li>Last Date:</li> <br /> <br />

              <li>Prelims' Date: </li> <br />
              <li>Mains Date: </li> <br />
              <li>Interview Date: </li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. Vacancy Details: Eligibility  </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li></li> <br />
              <li></li> <br />
              <li></li> <br />
              <li></li> <br />
              <li></li>  <br/>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>3. Job Profile </b></Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
            <ol>
              <li></li> <br />

              <li></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>




      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>4. Age Limit as on: </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
        <ul>
            <li><b>Min:</b> </li> <br />

            <li><b>Max: </b></li>
          </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>5. Pay Scale (Salary) </b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
          <ul>
            <li><b>Pay Scale: </b></li> <br />
            <li><b>Monthly (Present):</b></li>
          </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='accord-head'>6. Exam Details: Prelim / Mains / Interview </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li>Prelims:</li> <br />
              <li>Mains:</li> <br />
              <li>Interview:</li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header><b className='accord-head'>7. Documents Required:</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li></li> <br />
              <li></li> <br />
              <li></li> <br />
              <li></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>8. Application Fee:</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li>Gen / OBC / EWS: </li> <br />
              <li>SC / ST / PH: </li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>9. Important Links (Download / Apply): </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li>Download Notification: <a href="">Click Here</a></li> <br />
              <li>Apply: <a href="">Click Here</a></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>10. Contact us: To Apply Online</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li>Charges: ₹ </li> <br />
              <li>Mobile Number: </li> <br />
              <li>WhatsApp: <a href="">Click Here</a></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      {/* <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>11. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}





{/*       
      <br />
      <Accordion.Item eventKey="11">
        <Accordion.Header> <b className='accord-head'>12. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


      <br />

</Accordion>
</div>

      <hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
        </ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Jobformat


