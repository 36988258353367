import React from 'react'
import { Outlet, Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import Footer from '../pages/Footer'
import Learningslider from './Learningslider'

const Learnings = () => {
  return (
    <>
{/* Slider */}
<hr className="separator"/>
<Learningslider/>

<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b> Learnings: Growth Mindset </b> </h2>

<hr className="separator" />

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Personal Development</h3>
<ol>
<li><Link className={"link-styles"} to="/The7habitsofhighlyeffectivepeoplebystephenrcovey">The 7 Habits of Highly Effective People by Stephen R. Covey.  </Link></li>

  <li><Link className={"link-styles"} to="/Youcanwinbyshivkhera">You Can Win by Shiv Khera </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/Ikigaibybyfrancescmirallesandhectorgarcia">Ikigai by by Francesc Miralles and Hector Garcia </Link></li>

  <br />
  <h3 className='topic' style={{color: "green", fontSize: ""}}>Coming Soon</h3>
<br />

  <li><Link className={"link-styles"} to="/Learnings">The Alchemist by Paulo Coelho</Link> </li>

  {/* <li><Link className={"link-styles"} to="/Learnings">Attitude is Everything by Jeff Keller </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/Learnings">The Monk Who Sold his Ferrari by Robin Sharma </Link></li>
  <li><Link className={"link-styles"} to="/Learnings">The Power of Your Subconscious Mind by Joseph Murphy </Link></li>

  <br />

  <li><Link className={"link-styles"} to="/Learnings">The Power of Positive Thinking by Norman Vincent Peale </Link></li>

  <li><Link className={"link-styles"} to="/Learnings">How to Win Friends and Influence People by Dale Carnegie </Link></li>

  <br />
  <li><Link className={"link-styles"} to="/Learnings">Chanakya Niti: Collection of Best </Link></li>
  

 <br />
  <li><Link className={"link-styles"} to="/Learnings">Thinking, Fast and Slow by Daniel Kahneman</Link></li>
  <li><Link className={"link-styles"} to="/Learnings">Atomic Habits by James Clear </Link></li>  */}

</ol>

{/* <Link className={"link-styles"} to="/Learnings">7.  </Link> <br />
<Link className={"link-styles"} to="/Learnings">8. </Link> <br /> */}
<br />
  {/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}
</div>






<div className="sbox1 part2">
  <h3 className='topic'>Financial Knowledge</h3>
<ol>
  <li><Link className={"link-styles"} to="/Thinkandgrowrichbynapoleonhill">Think and Grow Rich by Napoleon Hill</Link> </li>
  <li><Link className={"link-styles"} to="/Richdadpoordadbyrobertkiyosaki">Rich Dad Poor Dad by Robert T. Kiyosaki    </Link></li>

  <li><Link className={"link-styles"} to="/Therichestmaninbabylonbygeorgesclason">The Richest Man in Babylon by George S. Clason  </Link></li>




  <br />
  <h3 className='topic' style={{color: "green", fontSize: ""}}>Coming Soon</h3>
<br />


  <li><Link className={"link-styles"} to="/Learnings">The Psychology of Money by Morgan Housel  </Link></li>

  {/* <li><Link className={"link-styles"} to="">The Intelligent Investors by Benjamin Graham</Link></li>
  <br />

  <li><Link className={"link-styles"} to="/Learnings">The Compound Effect by Darren Hardy </Link> </li>

  <br />
  
  <li><Link className={"link-styles"} to="/Dummypost">The Millionaire Fastlane by MJ Demarco </Link></li>
  <li><Link className={"link-styles"} to="/Learnings">The Total Money Makeover by Dave Ramsey </Link></li> */}
  
</ol>





{/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}

</div>
</div>
<hr className="separator" />





                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Autobiography / Skills</h3>
  <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1.. </b> <h4 className="clickhere">  Click Here</h4> </Accordion.Header>
        <Accordion.Body>
          <div className="accord-box jobdetails">
          <ol>

            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2.  </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        
    
        
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>4.  </b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">

          </div>
        </Accordion.Body>
      </Accordion.Item>





      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>5.  </b></Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
        <ol>
             
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      {/* <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='accord-head'>6.  </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>

            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}

      {/* <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header><b className='accord-head'>7. :</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>

        </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


      {/* <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>8. :</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
 
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


      
      {/* <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>9. : </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}




      


      {/* <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>10. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
             
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>  */}


      {/* <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>11. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>

        </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}
      
      {/* <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>11. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}





{/*       
      <br />
      <Accordion.Item eventKey="11">
        <Accordion.Header> <b className='accord-head'>12. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


      <br />

</Accordion>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Timeless Lessions / Quotes </h3>
<Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1.. </b> <h4 className="clickhere">  Click Here</h4> </Accordion.Header>
        <Accordion.Body>
          <div className="accord-box jobdetails">
          <ol>

            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2.  </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        
    
        
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>4.  </b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">

          </div>
        </Accordion.Body>
      </Accordion.Item>





      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>5.  </b></Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
        <ol>
             
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      {/* <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='accord-head'>6.  </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>

            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}

      {/* <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header><b className='accord-head'>7. :</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>

        </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


      {/* <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>8. :</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
 
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


      
      {/* <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>9. : </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}




      


      {/* <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>10. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
             
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>  */}


      {/* <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>11. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>

        </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}
      
      {/* <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>11. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}





{/*       
      <br />
      <Accordion.Item eventKey="11">
        <Accordion.Header> <b className='accord-head'>12. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


      <br />

</Accordion>




</div>
</div>


<hr className="separator" />


<WhatsAppShareButton/>
    <hr className="separator" />
    <br/>
    <Footer/>
    <Outlet />
</>
  )
}

export default Learnings
