import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';





// npm install react-helmet

function Youcanwinbyshivkhera({ }) {
  return (

    <>

<Helmet>
  <title>You Can Win by Shiv Khera: Summary by GumBaja</title>
  {/* <link rel="icon" href="%PUBLIC_URL%/images/habits.jpg" /> */}
  <meta name="description" content="You Can Win by Shiv Khera: Summary by GumBaja" />
  <meta property="og:title" content="You Can Win by Shiv Khera: Summary by GumBaja" />
  {/* <meta id="og-image" property="og:image" content="" /> */}
  <meta property="og:image" content="https://gumbaja.com/public/images/habits.jpg" />
</Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "black", fontSize: ""}}> <b>You Can Win by Shiv Khera: Summary by GumBaja </b> </h3>

<hr className="separator" />

<div className="content-box">
  <br />
<h3 className='topic'>Introduction</h3>  
<ul>
  <li><b>You Can Win</b> by <b>Shiv Khera</b></li>
  <li>This book emphasizes success, character building, positive attitude, self-discipline, gratitude, integrity, courage, empathy, wisdom, knowledge, and resilience for personal growth.</li> 
  <br />
  <li><b>"Winners don't do different things, they do things differently": Shiv Khera </b> </li>              
  <li>More than 4 million copies sold.</li>
</ul>

<hr className="separator" />
<Accordion> 
<Accordion.Item eventKey="0">
  <Accordion.Header> <b className='accord-head'>1. Importance of Attitude: </b> </Accordion.Header>
  <Accordion.Body>
    <div className="accord-box">
      <ul>
      <li>Attitude determines your success more than anything else. </li>
      <li>It's a choice, not a circumstance.</li>
      <li> Build it by focusing on the positive, avoiding procrastination, and practicing gratitude. </li>
      <li>Continuous learning and positive influences support a strong attitude.</li>
      <br />
      <li><b>Key takeaway: Your mindset is the biggest advantage you have.</b> </li>
      </ul>

    </div>
  </Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="1">
  <Accordion.Header> <b className='accord-head'>2. Winner's Habits: </b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
<li><b>Winners don't do different things. They do things differently.</b></li>
<li> Winners prioritize action over waiting for the <b>"right time."</b> </li>
<li>A good plan executed now is better than a perfect plan next week.</li>
<li> Develop the habit of <b>"doing it now"</b>  to combat procrastination.</li>
<li>Focus on completing tasks for satisfaction, not just rewards. </li>
<li>Small, consistent actions lead to greater success than sporadic bursts.</li>
<br />
<li><b>Key takeaway: Progress, not perfection, is the path to winning</b>. </li>

    </ul>
 
    </div>
  </Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="2">
  <Accordion.Header> <b className='accord-head'>3. Taking Responsibility:</b></Accordion.Header>
  <Accordion.Body> 
  <div className="accord-box">
    <ul>
<li>Blame external factors for failure keeps you powerless. </li>
<li>Accept responsibility for your choices and actions.</li>
<li>Own your mistakes and learn from them. </li>
<li>Turn problems into opportunities by taking proactive solutions.</li>
<br />
<li><b>Key takeaway: Empowered individuals create their own success.</b> </li>
    </ul>
    </div>
  </Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="3">
  <Accordion.Header><b className='accord-head'>4. The Power of Dreams: </b> </Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
<li><b>KNOWLEDGE helps you to reach your destination- provided you know what is the destination is.</b></li>
<li>Unless we focus, we cannot achieve our goal. It is hard to focus and concentrate, but it is a skill that can be learned.</li>
<li>Having clear, specific dreams gives you direction and motivation. </li> <br />
<li>Set SMART goals to translate your dreams into actionable steps.</li>
<li>Persist through challenges and setbacks with unwavering belief. </li>
<li>Celebrate small victories and stay focused on your ultimate goal.</li>
<br />
<li><b>Key takeaway: A defined roadmap leads to achieving your vision</b>. </li>


    </ul>


    </div>
  </Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="4">
  <Accordion.Header><b className='accord-head'>5. Developing Winning Relationships: </b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
<ul>          
    <li><b>Think Win-Win</b>.</li>
    <li>Discuss But Don't Argue.</li>
    <li>Building strong relationships is crucial for personal and professional success. </li>
    <li>Practice active listening and empathy to understand others.</li>
    <li>Communicate effectively and respectfully, even in disagreements. </li>
    <li>Appreciate and offer support to build trust and loyalty.</li>
    <br />
    <li><b>Key takeaway: Success is amplified through genuine connections</b>. </li>
</ul>

    </div>
  </Accordion.Body>
</Accordion.Item>

<br />
<Accordion.Item eventKey="5">
  <Accordion.Header><b className='accord-head'>6. Unleashing Your Potential:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
    <li>Discover your unique talents and abilities through self-reflection. </li>
  <li>Develop your strengths and learn to accept and work on your weaknesses.</li>
  <li>Continuously improve yourself through learning and personal growth. </li>
  <li>Embrace challenges as opportunities to grow and expand your potential.</li>
  <br />
  <li><b>Key takeaway: Recognizing and nurturing your natural gifts leads to higher achievements</b>. </li>

</ul>

    </div>
  </Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="6">
  <Accordion.Header> <b className='accord-head'>7. Leading with Your Heart:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
  <ul>
   <li>True leadership comes from integrity, empathy, and serving others. </li>
  <li>Practice selfless acts and motivate others to achieve their best.</li>
  <li>Create a positive and inspiring environment for yourself and those around you. </li>
  <li>Lead by example and empower others to take ownership and initiative.</li>
  <br />
  <li><b>Key takeaway: When you serve others, you naturally rise to leadership positions</b>. </li>
</ul>
    </div>
  </Accordion.Body>
</Accordion.Item>




<br />
<Accordion.Item eventKey="7">
  <Accordion.Header> <b className='accord-head'>8. The Power of Persistence:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
  <li>Overcoming obstacles and setbacks is key to reaching your goals. </li>
  <li>Develop resilience and bounce back from failures with determination.</li>
   <li>View challenges as stepping stones, not roadblocks. </li>
   <li>Never give up on your dreams, even when faced with adversity.</li>
   <br />
   <li><b>Key takeaway: Persistence and unwavering belief are essential for lasting success</b>. </li>

   </ul>
    </div>
  </Accordion.Body>
</Accordion.Item>







<br />
<Accordion.Item eventKey="8">
  <Accordion.Header> <b className='accord-head'>9. Living a Life of Value:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
        <li>Define your values and principles to guide your decisions and actions. </li>
        <li>Live with integrity and authenticity, staying true to yourself.</li>
        <li>Contribute positively to society and leave a lasting legacy. </li>
        <li>Create a life of meaning and purpose beyond material possessions.</li>
        <br />
        <li><b>Key takeaway: A values-driven life brings true fulfillment and satisfaction</b>. </li>
    </ul>

    </div>
  </Accordion.Body>
</Accordion.Item>






<br />
<Accordion.Item eventKey="9">
  <Accordion.Header> <b className='accord-head'>10. Important Learnings from this Book:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
    <li><b>Ability</b> teaches us how we do, <b>Motivation</b> determines why we do, and <b>Attitudes</b> decides how well we do.</li>
  <li>The winner has a solution for every problem; the loser has a problem for every solution. </li>
  <li>Never leave till tommorow, which you can do today. (Benjamin Franklin) </li>
  <br />
  <li>The Greatest Job Security is "Performance". </li>
  <li>I don't know the key to success, but the key to failure is trying to please everybody. (Bill Cosby) </li>
  <br />
  <br />
  <li>Ability will take you up there. Character will keep you there. </li>
  <br />
  <li>You have a choice in life: You can either pay the price of Discipline or Regret. (Tim Connor) </li>
  <br />
  <li>When people lack purpose and direction, they are unable to see opportunity. </li>
  <br />
  <li>Great minds have puposes, others have wishes. </li>
  <li>Technology and technicians you can always buy with money, but the wealthiest person must build relationships. </li>
    </ul>

    </div>
  </Accordion.Body>
</Accordion.Item>


<br />


<br />
<Accordion.Item eventKey="10">
  <Accordion.Header> <b className='accord-head'>Important Notes: Growth Mindset...!  </b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
      <ul>
        <li>Above Mentioned Timeless Lessions can Change Your Life, If You Practice It in Daily Lives.</li>
        <br />
        <li>These are just summaries. Reading the book in its entirety will provide a valuable insights and actionable advice.</li>
        <br />
        <li><b>Visit Daily and Read Valuable Contents to Push Your Mental-Limit.</b></li>
      </ul>
    </div>
  </Accordion.Body>
</Accordion.Item>



</Accordion>

<hr className="separator" />

<div className="autobox">
  <button className="buybotton"> <a href="https://amzn.to/4dZvLT5">Check: Buy</a></button>
</div>

<br />
</div>



<hr className="separator" />

<WhatsAppShareButton />

<hr className="separator" />


<div className="relatedposts">
<h3>Related Posts</h3>
<ol>
<li><Link className={"link-styles"} to="/Ikigaibybyfrancescmirallesandhectorgarcia">Ikigai by by Francesc Miralles and Hector Garcia </Link></li>
<li><Link className={"link-styles"} to="/The7habitsofhighlyeffectivepeoplebystephenrcovey">The 7 Habits of Highly Effective People by Stephen R. Covey.  </Link></li>
<li><Link className={"link-styles"} to="/Thinkandgrowrichbynapoleonhill">Think and Grow Rich by Napoleon Hill</Link> </li>
<br />
<li><Link className={"link-styles"} to="/Youcanwinbyshivkhera">You Can Win by Shiv Khera </Link></li>

<li><Link className={"link-styles"} to="/Therichestmaninbabylonbygeorgesclason">The Richest Man in Babylon by George S. Clason  </Link></li>
</ol>
</div>

<br />


{/* <h1 style={{
textAlign: 'center',
border: 'solid 2px black',
}}> Dummy Contents</h1> */}

<Footer />

<Outlet />
    </>



  );
}
export default Youcanwinbyshivkhera;


