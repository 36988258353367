import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import WhatsAppShareButton from '../component/WhatsAppShareButton'




const Latestposts = () => {
  return (
    <>

<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Latest Posts </b> </h2>

<hr className="separator" />
<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>New Posts</h3>
  <ol>
  <li><Link className={"link-styles"} to="/The7habitsofhighlyeffectivepeoplebystephenrcovey">The 7 Habits of Highly Effective People by Stephen R. Covey.  </Link></li>
  <li><Link className={"link-styles"} to="/Youcanwinbyshivkhera">You Can Win by Shiv Khera </Link></li> 
  <br />
  <li><Link className={"link-styles"} to="/Thinkandgrowrichbynapoleonhill">Think and Grow Rich by Napoleon Hill</Link></li>

  <li><Link className={"link-styles"} to="/Richdadpoordadbyrobertkiyosaki">Rich Dad Poor Dad by Robert T. Kiyosaki    </Link></li>


  <li><Link className={"link-styles"} to="/Therichestmaninbabylonbygeorgesclason">The Richest Man in Babylon by George S. Clason  </Link></li>
  <br />

  <li><Link className={"link-styles"} to="/Ibpspoform2024">IBPS PO Form 2024 (4455 Posts) </Link></li>
<li><Link className={"link-styles"} to="/Irdaiassistantmanagerform2024">IRDAI Assistant Manager Form 2024 (49 Posts)</Link></li>
  

<br />
<li><Link className={"link-styles"} to="/Nainitalbankpoandotherposts">Nainital Bank PO and Others (25 Posts)</Link></li>

</ol>

</div>




<div className="sbox1 part2">
<h3 className='topic'>Recent Posts </h3>
<ol>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>
  <li><Link className={"link-styles"} to=""> </Link></li>

</ol>
<br />

</div>
</div>

<br />
<br />

<hr className="separator" />
                           {/* Second Part */}

    
  
    <WhatsAppShareButton/>
    <hr className="separator" />
    <br/>
    <Footer/>
    <Outlet />
    </>
  )
}

export default Latestposts


