import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import Footer from '../pages/Footer';
import Accordion from 'react-bootstrap/Accordion';

const Security = () => {
  return (
<>

<Helmet>
  <title>Security and Disaster Management by GumBaja</title>
  <meta name="description" content="Environment" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>


<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Security and Disaster Management </b> </h2>

<hr className="separator" />

{/* Part 1 */}
<div className="Mainbox1">
<div className="sbox1">
  
<div>
  <Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='recomm'>Books:</h3>  - (Click Here)</Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <ol>
            
          <h3 className='recomm'>Toppers' Recommended:</h3>
              <li>Internal Security and Disaster Management Book: <a href="https://amzn.to/3YTmAPO">Click Here</a></li> <br />
          </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />
  </Accordion>
</div>


  <h3 className='topic'>Security and Security Challenges</h3>

  <ol>
  <li><Link className={"link-styles"} to="">What is Security? </Link></li>
  <li><Link className={"link-styles"} to="">Linkages between development and spread of extremism. </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Role of external state and non-state actors in creating challenges to internal security. </Link></li>

  <li><Link className={"link-styles"} to="">Challenges to internal security through communication networks, role of media and social networking sites in internal security challenges, basics of cyber security; money-laundering and its prevention. </Link></li>
  <br />

  <li><Link className={"link-styles"} to="">Security challenges and their management in border areas; linkages of organized crime with terrorism. </Link></li>
  <li><Link className={"link-styles"} to="">Various Security forces and agencies and their mandate. </Link></li>
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Disaster and Disaster Management </h3>

<ol>
  <li><Link className={"link-styles"} to="">What are Disasters? </Link></li>
  <li><Link className={"link-styles"} to="">Disasters in India and World </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Disaster Management? </Link></li>
  <li><Link className={"link-styles"} to="">Forces in Disaster Management? </Link></li>
</ol>

</div>
</div>
<hr className="separator" />

<WhatsAppShareButton/>





<hr className="separator" />

<div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/History">History </Link></li>
          <li><Link className={"link-styles"} to="/Geography">Geography </Link></li>
          <li><Link className={"link-styles"} to="/Psir">Polity </Link></li>
          <li><Link className={"link-styles"} to="/Economy">Economy </Link></li>
          <li><Link className={"link-styles"} to="/Environment">Environment </Link></li>
          <li><Link className={"link-styles"} to="/Science">Science and Technology </Link></li>
          <li><Link className={"link-styles"} to="/English">Basic English Grammar </Link></li>
        </ol>
      </div>




    <hr className="separator" />
    <br/>
  <Footer/>
  <Outlet />
</>
  )
}

export default Security
