import React from 'react'
import { Outlet, Link } from "react-router-dom";
import WhatsAppShareButton from '../component/WhatsAppShareButton';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../pages/Footer'


const History = () => {
  return (
<>
<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>History: India and World</b> </h2>

<hr className="separator" />



<div className="Mainbox1">
<div className="sbox1">
                                 {/* Books */}
<div>
  <Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='recomm'>Books: Topper's Choice  </h3></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <ol>

          <li>Ancient and Medieval History: <a href="https://amzn.to/4fSc9lM">Tamil Nadu Board (Class 11th) </a></li> <br />
          
          <li>Modern History: <a href="https://amzn.to/4dogvPX"> A Brief History of Modern India by Rajiv Ahir (Spectrum) </a></li>

          <li>Indian Art and Culture: <a href="https://amzn.to/3WY6wty"> by Nitin Singhania</a> </li> <br />

          <li>Society: <a href="">Coming Soon</a></li>
          
          <li>World History: <a href="https://amzn.to/4dyiQHZ">Contemporary World History: NCERT Class 9th</a></li>

      </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
  </Accordion>
</div>
  <br />
  <h3 className='topic'>Ancient India History</h3>

  <ol>
  <li><Link className={"link-styles"} to="/Dummypost">Sources of History </Link></li>
  {/* <li><Link className={"link-styles"} to="">Chronological Events in Ancient to 750 AD</Link></li> */}
  <li><Link className={"link-styles"} to="">India- Geographical Features and their Impact on History </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">The Stone Age </Link></li>
  <li><Link className={"link-styles"} to="">The Harappan Culture / Civilization </Link></li>
  <li><Link className={"link-styles"} to="">The Vedic Culture </Link></li>
  <br />  
  <li><Link className={"link-styles"} to="">India of Pre-Mauryan period: Mahajanapadas- The Rise of Magadha and Alexander's Invasion </Link></li>
  <li><Link className={"link-styles"} to="">Jainism </Link></li>
  <li><Link className={"link-styles"} to="">Buddhism </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">The Mauryan Empire </Link></li>
  <li><Link className={"link-styles"} to="">Post - Mauryan India </Link></li>
  <li><Link className={"link-styles"} to="">Sangam Age </Link></li>
  <br />  
  <li><Link className={"link-styles"} to="">Gupta Empire </Link></li>
  <li><Link className={"link-styles"} to="">Harshavardhana (606- 647 A.D) </Link></li>
  <li><Link className={"link-styles"} to="">South Indian Kingdoms: Pallavas </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">South Indian Kingdoms-II: Chalukyas and Rashtrakutas </Link></li>
  <li><Link className={"link-styles"} to="">South Indian Kingdoms: Cholas </Link></li>
  <li><Link className={"link-styles"} to="">The Spread of Indian Culture in other Countries </Link></li>
</ol>
</div>




<div className="sbox1 part2">
  <br />
<h3 className='topic'>Medieval India History</h3>

<ol>
  {/* <li><Link className={"link-styles"} to="">Chronological Events from 750 to 1757 </Link></li> */}
  <li><Link className={"link-styles"} to="">Early Medieval India (750 - 1200) </Link></li>
  <li><Link className={"link-styles"} to="">Delhi Sultanate </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Bhakti Movement in Medieval India </Link></li>
  <li><Link className={"link-styles"} to="">Regional Kingdoms </Link></li>
  <li><Link className={"link-styles"} to="">Vijayanagar Empire and Bahmani Kingdom </Link></li>

  <br />

  <li><Link className={"link-styles"} to="">The Mughal Empire </Link></li>
  <li><Link className={"link-styles"} to="">The Marathas </Link></li>
  <li><Link className={"link-styles"} to="">The Coming of Europeans </Link></li>
</ol>

</div>
</div>
<hr className="separator" />



<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'> Modern India History</h3>

  <ol>
  {/* <li><Link className={"link-styles"} to="">Important Events/Policies from 1757 to 1947: Chronology </Link></li> */}
  <li><Link className={"link-styles"} to="">The Rise of British in India: How?</Link></li>
  <li><Link className={"link-styles"} to="">Revolt of 1857: Places and Leaders </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">British Administration before and after 1857 (Changes) </Link></li>
  <li><Link className={"link-styles"} to="">Development of Civil Services in India </Link></li>
  <li><Link className={"link-styles"} to="">British: Education Policy in India </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">British: Economic Policies in India </Link></li>
  <li><Link className={"link-styles"} to="">Impacts of British Administration </Link></li>
  <li><Link className={"link-styles"} to="">Press in India under British Rule </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Reformation Movements and Important Leaders </Link></li>
  <li><Link className={"link-styles"} to="">Formation of INC and Its Important Sessions </Link></li>
  <li><Link className={"link-styles"} to="">Peasants, Tribal, Working Class and Other Movements </Link></li>

  <br />
  <li><Link className={"link-styles"} to="">Indian National Movements-I (1905-1917) </Link></li>
  <li><Link className={"link-styles"} to="">Indian National Movements-II (1918-1929) </Link></li>
  <li><Link className={"link-styles"} to="">Indian National Movements-III (1930-1947) </Link></li>

  <br />
  <li><Link className={"link-styles"} to="">Lists of Governors, Governor Generals to Viceroy and Events during their Regimes </Link></li>
  <li><Link className={"link-styles"} to="">Important Personalities (Freedom Fighters) </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>

<br />
</div>




<div className="sbox1 part2">
<h3 className='topic'>Art and Culture  </h3>

<ol>

  <li><Link className={"link-styles"} to="">Introduction to Indian Art and Culture </Link></li>
  <li><Link className={"link-styles"} to="">Indian Sculpture </Link></li>
  <li><Link className={"link-styles"} to="">Indian Architecture </Link></li>

  <br />

  <li><Link className={"link-styles"} to="">Temple Architecture </Link></li>
  <li><Link className={"link-styles"} to="">Pottery Tradition </Link></li>
  <li><Link className={"link-styles"} to="">Indian Paintings </Link></li>

  <br />

  <li><Link className={"link-styles"} to="">Printing, weaving and Embroidery Styles of India </Link></li>
  <li><Link className={"link-styles"} to="">Coinage System </Link></li>
  <li><Link className={"link-styles"} to="">Indian dance Forms </Link></li>
  <br />

  <li><Link className={"link-styles"} to="">Indian Music </Link></li>
  <li><Link className={"link-styles"} to="">Indian Theatre Arts </Link></li>
  <li><Link className={"link-styles"} to="">Martial Arts in India </Link></li>

  <br />

  <li><Link className={"link-styles"} to="">Puppetry </Link></li>
  <li><Link className={"link-styles"} to="">Indian Literature </Link></li>
  <li><Link className={"link-styles"} to="">Languages of India (Ancient to present) </Link></li>

  <br />

  <li><Link className={"link-styles"} to="">Fairs and Festivals of India </Link></li>
  <li><Link className={"link-styles"} to="">World Heritage Sites in India </Link></li>
  <li><Link className={"link-styles"} to="">Important Art and Culture Topics and Facts </Link></li>
</ol>


<br />
</div>
</div>
<hr className="separator" />


{/* Next Box */}


<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>India After Independence </h3>
  <ol>
  {/* <li><Link className={"link-styles"} to="">Important Events from 1948 to 2024: Chronology </Link></li> */}
  <li><Link className={"link-styles"} to="">Journey of India from 1947 to 2024 under All PMs </Link></li>
  <li><Link className={"link-styles"} to="">Important Personalities: Nation Builders </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Important Government Policies/Schemes from 1947 to 2024 </Link></li>
 
  <br />



  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>
<br />


<br />
<br />

<h3 className='topic'>Indian Society </h3>
<ol>
<li><Link className={"link-styles"} to="">Indian Society </Link></li>
</ol>

  {/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}


</div>




<div className="sbox1 part2">
<h3 className='topic'>World History</h3>
<ol>

  <li><Link className={"link-styles"} to="">Modern World History (from 18th C): Chronology</Link></li>
  <li><Link className={"link-styles"} to="">Industrial Revolution </Link></li>
  <li><Link className={"link-styles"} to="">Colonization </Link></li>
  <li><Link className={"link-styles"} to="">Decolonization </Link></li>
  <li><Link className={"link-styles"} to="">World Wars </Link></li>
  <li><Link className={"link-styles"} to="">Redraw of National Boundaries </Link></li>
  <li><Link className={"link-styles"} to="">Political philosophies such as capitalism, socialism, communism, etc.- their forms and consequences on society. </Link></li>
  <li><Link className={"link-styles"} to="">American Revolution </Link></li>
  <li><Link className={"link-styles"} to="">Russian Revolution </Link></li>
  <li><Link className={"link-styles"} to="">French Revolution </Link></li>
  <li><Link className={"link-styles"} to="">Imperialism </Link></li>
  <li><Link className={"link-styles"} to="">Nazism </Link></li>
  <li><Link className={"link-styles"} to="">Fascism </Link></li>
  <li><Link className={"link-styles"} to="">Unification of Italy and Germany </Link></li>
  <li><Link className={"link-styles"} to="">Cold War: Bi-polar World </Link></li>
  <li><Link className={"link-styles"} to="">Disintegration of USSR and Uni-polar World </Link></li>
  
  <br />
  {/* <li><Link className={"link-styles"} to="/Dummypost">Format </Link></li> */}
</ol>
{/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}


<br />




</div>
</div>
<hr className="separator" />

<WhatsAppShareButton/>


<hr className="separator" />

<div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/History">History </Link></li>
          <li><Link className={"link-styles"} to="/Geography">Geography </Link></li>
          <li><Link className={"link-styles"} to="/Psir">Polity </Link></li>
          <li><Link className={"link-styles"} to="/Economy">Economy </Link></li>
          <li><Link className={"link-styles"} to="/Environment">Environment </Link></li>
          <li><Link className={"link-styles"} to="/Science">Science and Technology </Link></li>
        </ol>
      </div>
    <hr className="separator" />


    
    <br/>
  <Footer/>
  <Outlet />
</>
  )
}

export default History

