import { Outlet, Link } from "react-router-dom";


const Layout = () => {
  return (
    <>
      <nav className="navbar">
            <Link className={"link-style"} to="/">GumBaja</Link>
            <Link className={"link-style"} to="/Review">Reviews</Link>
            
      </nav>

      <nav className="navbar2">
           <Link className={"link-style"} to="/Learnings">'Learnings'</Link> 


            <Link className={"link-style"} to="/Jobs">'Jobs'</Link>

    
      </nav>

      <Outlet />
    </>
  )
};

export default Layout;