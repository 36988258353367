import React from 'react';
import { Outlet, Link } from "react-router-dom";
import WhatsAppShareButton from '../component/WhatsAppShareButton';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../pages/Footer';


const Science= () => {
  return (
<>
<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Science and Technology</b> </h2>

<hr className="separator" />

{/* Part 1 */}
<div className="Mainbox1">
<div className="sbox1">
  
<div>
  <Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='recomm'>Books:</h3>  - (Click Here)</Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <ol>
            
          <h3 className='recomm'>Toppers' Recommended:</h3>
              <li>Science and Technology: <a href="https://amzn.to/3yKDhT7">Click Here</a></li> <br />
          </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />
  </Accordion>
</div>
  <h3 className='topic'>Basic Science</h3>
  <ol>
  <li><Link className={"link-styles"} to="">Basics of Biology </Link></li>
  <li><Link className={"link-styles"} to="">Basics of Chemistry </Link></li>
  <li><Link className={"link-styles"} to="">Basics of Physics </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Human Health and Diseases </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Achievements of Indians in Science and Technology</h3>

<ol>
  <li><Link className={"link-styles"} to="">Indigenization of Technology </Link></li>
  <li><Link className={"link-styles"} to="">Developing New Technology </Link></li>
  <li><Link className={"link-styles"} to="">India's Defence Technology </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">World's Defence Technology </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>

</div>
</div>
<hr className="separator" />



{/* Part 2 */}
<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Emerging Technologies</h3>

  <ol>
  <li><Link className={"link-styles"} to="">Information and Communication Technology (ICT) </Link></li>
  <li><Link className={"link-styles"} to="">Compputers </Link></li>
  <li><Link className={"link-styles"} to="">Robotics Technology </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Nano-Technology </Link></li>
  <li><Link className={"link-styles"} to="">Bio-Technology </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <br /> 
  <li><Link className={"link-styles"} to="">Intellectual Property Rights (IPR) and Related Issues </Link></li>
  <li><Link className={"link-styles"} to="">Artificial Intelligence </Link></li>
  <li><Link className={"link-styles"} to="">Drone Technology </Link></li>
  <br />

  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Space Technology</h3>
<ol>
  <li><Link className={"link-styles"} to="">Basic Terms in the Space Technology </Link></li>
  <li><Link className={"link-styles"} to="">Interntional Space Agency and Their Space Missions </Link></li>
  <li><Link className={"link-styles"} to="">Introduction of ISRO and Space Mission </Link></li>

  <br />  
  <li><Link className={"link-styles"} to="">Lunch Vehicles and Technology </Link></li>
  <li><Link className={"link-styles"} to="">Important Satellites of India </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>

</div>
</div>
<hr className="separator" />




{/* Part 3 */}
<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Science Facts</h3>
  <ol>
  <li><Link className={"link-styles"} to="">Important Personalities in the field of Science </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
  <br />
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>


</div>




<div className="sbox1 part2">
<h3 className='topic'>Science News</h3>
<ol>
  <li><Link className={"link-styles"} to="/Dummypost">Format </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}

  <br />
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>

</div>
</div>
<hr className="separator" />


<WhatsAppShareButton/>




<hr className="separator" />

<div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/History">History </Link></li>
          <li><Link className={"link-styles"} to="/Geography">Geography </Link></li>
          <li><Link className={"link-styles"} to="/Psir">Polity </Link></li>
          <li><Link className={"link-styles"} to="/Economy">Economy </Link></li>
          <li><Link className={"link-styles"} to="/Environment">Environment </Link></li>
          <li><Link className={"link-styles"} to="/Science">Science and Technology </Link></li>
          <li><Link className={"link-styles"} to="/English">Basic English Grammar </Link></li>
        </ol>
      </div>





    <hr className="separator" />
    <br/>
  <Footer/>
  <Outlet />
</>
  )
}

export default Science
