import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
import Accordion from 'react-bootstrap/Accordion';

import Footer from '../pages/Footer'

const Economy = () => {
  return (
<>
<Helmet>
  <title>Indian Economy by GumBaja</title>
  <meta name="description" content="Indian Economy" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>




<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Economy: India and World</b></h2>

<hr className="separator" />

<div className="Mainbox1">
<div className="sbox1">
<div>
  <Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='recomm'>Books: Topper's Choice</h3></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <ol>
              <li>Economy Book: <a href="https://amzn.to/4fWKYWO">Ramesh Singh</a></li>
              <li>Economic Survey Latest: <a href="">Click Here</a></li>
          </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
  </Accordion>
</div>
  <h3 className='topic'>Basic of Economy</h3>
  <ol>
  <li><Link className={"link-styles"} to="">Basics of Economy: Types </Link></li>
  <li><Link className={"link-styles"} to="">History: Evolution of Indian Economy (Journey) </Link></li>
  <li><Link className={"link-styles"} to="">Sectors of Economy: Growth Story </Link></li>

  <br />
  <li><Link className={"link-styles"} to="">Economic Planning in India: Inclusive Growth, Development and Employment </Link></li>
  <li><Link className={"link-styles"} to="">Land reforms in India. </Link></li>
  <li><Link className={"link-styles"} to="">Economic Reforms in India: Before and After LPG Reforms </Link></li>

  <br />

  <li><Link className={"link-styles"} to="">Govt. Budgeting </Link></li>
  <li><Link className={"link-styles"} to="">National Income </Link></li>
  <li><Link className={"link-styles"} to="">Tax Structure in India </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Public Finance in India </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Indian Economy</h3>
<ol>
  <li><Link className={"link-styles"} to="">Agriculture in India: Cropping patterns | Irrigation System </Link></li>
  <li><Link className={"link-styles"} to="">Subsidy, MSP and PDS Related Issues </Link></li>
  <li><Link className={"link-styles"} to="">Economic of Animal Rearing </Link></li>
  <br />  
  <li><Link className={"link-styles"} to="">Industrial Sectors in India (Food Processing, etc) </Link></li>
  <li><Link className={"link-styles"} to="">Infrastructure: Energy, Ports, Roads, Airports, Railways etc. </Link></li>
  <li><Link className={"link-styles"} to="">Investment models in India. </Link></li>
<br />
<li><Link className={"link-styles"} to="">Population: Asset or Liabilities? (Poverty, Inequality, Demographic Dividend) </Link></li>
  <li><Link className={"link-styles"} to="">Start Up and MSMEs Sector in India </Link></li>
  <li><Link className={"link-styles"} to="">Government Schemes in India </Link></li>

</ol>

</div>
</div>
<hr className="separator" />






{/* Part */}
<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Banking and Financial Sectors</h3>

  <ol>
  <li><Link className={"link-styles"} to="">Banking in India (Money, MPC) </Link></li>
  <li><Link className={"link-styles"} to="">Financial and Security Market </Link></li>
  <li><Link className={"link-styles"} to="">What is an Inflation? Types | Role of Govt and RBI </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">External Sectors in India </Link></li>
  <li><Link className={"link-styles"} to="">International Financial Institution and India </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Terms, Meaning and Fact</h3>
<ol>
  <li><Link className={"link-styles"} to="">Important Terms: Meaning </Link></li>
  <li><Link className={"link-styles"} to="">Current Affairs Topics </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}


</ol>
<br />


</div>
</div>
<hr className="separator" />

<WhatsAppShareButton/>




<hr className="separator" />

<div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/History">History </Link></li>
          <li><Link className={"link-styles"} to="/Geography">Geography </Link></li>
          <li><Link className={"link-styles"} to="/Psir">Polity </Link></li>
          <li><Link className={"link-styles"} to="/Economy">Economy </Link></li>
          <li><Link className={"link-styles"} to="/Environment">Environment </Link></li>
          <li><Link className={"link-styles"} to="/Science">Science and Technology </Link></li>
          <li><Link className={"link-styles"} to="/English">Basic English Grammar </Link></li>
        </ol>
      </div>



    <hr className="separator" />

    
    <br/>
  <Footer/>
  <Outlet />
</>
  )
}

export default Economy
