import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';





// npm install react-helmet

function Formatreview2({ }) {
  return (

    <>

      <Helmet>
        <title>Reviews by GumBaja</title>
        <meta name="description" content="Page description" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green", fontSize: ""}}> <b>Reviews: </b> </h3>

<hr className="separator" />

<div className="content-box">
        <br />
        The information provided on www.gumbaja.com is for general informational purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. <br />
        <br /><br />

<Accordion>
<br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. Newn Things. </b> <span className='clickhere' > - Click Here</span></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          


          <div className="autobox">
        <button className="buybotton"> <a href="">Check: Buy</a></button>
      </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />



      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            


        <div className="autobox">
        <button className="buybotton"> <a href="">Check: Buy</a></button>
      </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="2">
        <Accordion.Header> <b className='accord-head'>3. </b></Accordion.Header>
        <Accordion.Body> 
        <div className="accord-box">
            


        <div className="autobox">
        <button className="buybotton"> <a href="">Check: Buy</a></button>
      </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>4. </b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
            



        <div className="autobox">
        <button className="buybotton"> <a href="">Check: Buy</a></button>
      </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='accord-head'>5. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            


        <div className="autobox">
        <button className="buybotton"> <a href="">Check: Buy</a></button>
      </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>



      <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header><b className='accord-head'>6. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
          


        <div className="autobox">
        <button className="buybotton"> <a href="">Check: Buy</a></button>
      </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>7. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            


        <div className="autobox">
        <button className="buybotton"> <a href="">Check: Buy</a></button>
      </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>8. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            


        <div className="autobox">
        <button className="buybotton"> <a href="">Check: Buy</a></button>
      </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>




      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>9. </b></Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
            



        <div className="autobox">
        <button className="buybotton"> <a href="">Check: Buy</a></button>
      </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>



      
      <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>10. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>

          
      <div className="autobox">
        <button className="buybotton"> <a href="">Check: Buy</a></button>
      </div>
        </Accordion.Body>
      </Accordion.Item>



</Accordion>
<br />
</div>
      <hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
        </ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Formatreview2


