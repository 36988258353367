import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Whatsappchat from '../component/Whatsappchat';




// npm install react-helmet

function Nainitalbankpoandotherposts({ }) {
  return (

    <>


      <Helmet>
        <title>Nainital Bank Probationary Officer PO, IT Officer, Manager IT and CA Recruitment 2024</title>
        <meta name="description" content="Nainital Bank Probationary Officer PO, IT Officer, Manager IT and CA Recruitment 2024" />
        <meta property="og:title" content="Nainital Bank Probationary Officer PO, IT Officer, Manager IT and CA Recruitment 2024" />
        {/* <meta id="og-image" property="og:image" content="path/images/cat1.jpg" /> */}
      </Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>Nainital Bank Probationary Officer PO, IT Officer, Manager IT and CA Recruitment 2024 </b></h3>

<hr className="separator" />


<div className="content-box">
        <br /> 
    
          <ul className='jobheadline'>
          <li><b>Nainital Bank Probationary Officer PO, IT Officer, Manager IT and CA Recruitment 2024 </b></li>
          <br />

          <li><b>Total: 25 Post</b></li>
           
          </ul>
       
        
        <br />
        <hr className="separator" />
<Accordion>
<br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. Important Dates: .. </b> <h4 className="clickhere">  Click Here</h4> </Accordion.Header>
        <Accordion.Body>
          <div className="accord-box jobdetails">
          <ol>
              <li>Application Start: <b>17/08/2024</b> </li> <br />
              <li>Last Date: <b>31/08/2024</b> </li> <br />
              
              <li>Exam Date: <b>September Second Week 2024</b> </li> <br />
        
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. Vacancy Details: Eligibility  </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
      
              <li>Probationary Officer PO Grade I (20 Post): (Bachelor Degree / Master Degree in Any Stream with minimum 50% Marks.)</li> <br />
              <li>IT Officer (2 Post): (Bachelor / Master Degree in Computer Science / IT / Cyber Security / Electronics / Electronics & Communication / Electronics and Instrumentation.)</li> <br />
              <li>Manager IT (2 Posts): (Bachelor Degree / Master Degree in Computer Science / Computer Application / IT / Electronics / Electronics & Telecommunications/ Electronics & Communication/ Electronics & Instrumentation)</li> <br />
              <li>Chartered Accountant CA (1 Post): ACA / FCA Degree from ICAI</li> <br />
            </ol>

            <ul>
              <li><b> Age Limit (as on 31/07/2024):</b> Age: 21-32 Years (for Probationary Officer PO Grade I and IT Officer).</li>
              <li><b> Age Limit (as on 31/07/2024):</b> Age: 25-35 Years (for Manager IT).</li>
              <li><b> Age Limit (as on 31/07/2024):</b> Age: 25-40 Years (for CA).</li>
            </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>4. Pay Scale (Salary) </b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
          <ul>
             <li><b>Monthly (Present):</b> Check Page No. 3 of Official Notification. <a href="https://www.nainitalbank.co.in/pdf/NOTIFICATION%20OF%20OFFICER%20IBPS.pdf">Click Here</a></li>
          </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>





      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>5. Job Profile </b></Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
        <ol>
              <li>Banking Services</li> <br />
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='accord-head'>6. Exam Details: Prelim / Mains / Interview </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li>Examms (Page-11): <a href="https://www.nainitalbank.co.in/pdf/NOTIFICATION%20OF%20OFFICER%20IBPS.pdf">Click Here</a></li> <br />
              
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header><b className='accord-head'>7. Documents Required:</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
              <li>Photograph</li> <br />
              <li>Signature</li> <br />
              <li>Left thumb impression</li> <br />
              <li>A hand written declaration</li> <br />
        </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>8. Application Fee:</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
              <li><b>Gen / OBC / EWS: </b> Rs 1500/-</li> <br />
              <li><b>SC / ST / PH:</b> Rs 1500/-</li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>9. Important Links (Download / Apply): </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>
              <li>Download Notification: <a href="https://www.nainitalbank.co.in/pdf/NOTIFICATION%20OF%20OFFICER%20IBPS.pdf">Click Here</a></li> <br />

              <li><b>Check Exam Centers Carefully.</b></li>
              <li>Apply: <a href="https://ibpsonline.ibps.in/nblpoaug24/">Click Here</a></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>




      





      
    <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>10. Best Rated Books: Click Here</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>
          <h4>Study and Practice these 2 Books for PO.</h4>
              <li><a href="https://amzn.to/3XfdeNh">All Banks PO PYQs 2019-2023</a></li>
              <br />
              <li><a href="https://amzn.to/3Xj8eaz">IBPS PO Pre and Mains Solved Papers (2011-2023)</a></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> 


      <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>11. Contact us: To Apply Online</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>
              <li>Chargeable </li> <br />
              <li>Call/WhatsApp: 07005369425  </li> <br />
              <Whatsappchat/>
              <br />
              <li>Join WhatsApp Group: <a href="https://chat.whatsapp.com/EHZC9jtiDtbCt3MsJwArNG">Click Here</a></li>
              <li>Follow WhatsApp Channel: <a href="https://whatsapp.com/channel/0029Val5Qj1EKyZFje1gXx21">Click Here </a></li>
              <br />
              <li>Telegram Channel: <a href="https://t.me/gumbaja29">Click here</a></li>
        </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>


{/*       
      <br />
      <Accordion.Item eventKey="11">
        <Accordion.Header> <b className='accord-head'>12. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


      <br />

</Accordion>
</div>

      <hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
        <li><Link className={"link-styles"} to="/Ibpspoform2024">IBPS PO Form 2024 </Link></li>

<li><Link className={"link-styles"} to="/Irdaiassistantmanagerform2024">IRDAI Assistant Manager Form 2024 | 49 Posts</Link></li>
  
        </ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Nainitalbankpoandotherposts;



