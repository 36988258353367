import React from 'react'
import { Outlet, Link } from "react-router-dom";
import WhatsAppShareButton from '../component/WhatsAppShareButton';
import Footer from '../pages/Footer';
import Jobslider from './jobslider/Jobslider';
import { Helmet } from 'react-helmet';

const Jobs = () => {
  return (
    <>


<Helmet>
        <title>Job updates: Latest Jobs</title>
        <meta name="description" content="Page description" />
        <link rel="icon" href="%PUBLIC_URL%/gumbaja.png" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>


{/* Slider */}
<hr className="separator" />

<Jobslider/>

<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Latest Job Updates </b></h2>

<hr className="separator" />

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'><b>Latest Jobs</b></h3>
<ol>

<li><Link className={"link-styles"} to="/Irdaiassistantmanagerform2024">IRDAI Assistant Manager Form 2024 (49 Posts)</Link></li>

<br />
<li><Link className={"link-styles"} to="">Coming Soon </Link></li>
<li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  

<br />


  {/* <li><Link className={"link-styles"} to="/Jobs"> 2024 </Link></li> */}
  {/* <li><Link className={"link-styles"} to="/Jobs"> 2024 </Link></li> */}
  {/* <li><Link className={"link-styles"} to="/Jobs"> 2024 </Link></li> */}
  {/* <li><Link className={"link-styles"} to="/Jobs"> 2024 </Link></li> */}
</ol>
   <br />

  {/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}
</div>




<div className="sbox1 part2">
<h3 className='topic'><b>Last Date Over & Admit Cards</b></h3>
<ol>
<li><Link className={"link-styles"} to="/Nainitalbankpoandotherposts">Nainital Bank PO and Others (25 Posts)</Link></li>
<li><Link className={"link-styles"} to="/Ibpspoform2024">IBPS PO Form 2024 (4455 Posts) </Link></li>

  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}


  {/* <li><Link className={"link-styles"} to="/Jobformat">Format </Link></li> */}
</ol>
 <br />


{/* <div className="btn-group">
  <button> <a href="">More</a></button>
</div> */}

</div>
</div>
<hr className="separator" />

<WhatsAppShareButton/>
    <hr className="separator" />
    <br/>
    <Footer/>
    <Outlet />
</>
  )
}

export default Jobs

