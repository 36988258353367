import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../../pages/Footer';
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';





// npm install react-helmet

function Richdadpoordadbyrobertkiyosaki({ }) {
  return (

    <>

<Helmet>
  <title>Rich Dad Poor Dad by Rober T. Kiyosaki Summary by GumBaja</title>
  <meta name="description" content="Rich Dad Poor Dad by Rober T. Kiyosaki Summary by GumBaja" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green", fontSize: ""}}> <b>Rich Dad Poor Dad by Rober T. Kiyosaki Summary by GumBaja </b> </h3>

<hr className="separator" />

<div className="content-box">
<br />
<h3 className='topic'>Introduction</h3> 



<ul>
  <li><b>Introduces the two father figures:-</b> <br />
       i). Poor Dad: His biological father. <br />
       ii). Rich Dad: Friend's father. <br /> <br /></li>
  <li>Highlights the contrasting financial philosophies and attitudes toward money and wealth. </li>
  <li>Emphasizes the importance of financial education beyond formal schooling.</li>
  <li>Discusses the impact of beliefs and attitudes about money on financial success.
  </li>
  <li>Sets the stage for the rest of the book by illustrating the different approaches to wealth-building.</li>
</ul>
 


<Accordion>
<br />
<Accordion.Item eventKey="0">
  <Accordion.Header> <b className='accord-head'>1. The Rich Don't Work for Money: </b> <span className='clickhere' >..(Click Here)</span></Accordion.Header>
  <Accordion.Body>
    <div className="accord-box">
    <ul>
    <li>Challenges the traditional notion of working for money and the importance of financial independence.</li>
    <li>Introduces the concept of making money work for you through investments and passive income.</li>
    <li>Emphasizes the value of financial education and the ability to make informed financial decisions.</li>
    <li>Discusses the importance of taking calculated risks to achieve financial success.</li>
    <li>Encourages readers to focus on acquiring assets that generate income rather than solely relying on a job.</li>
    </ul>
    </div>
  </Accordion.Body>
</Accordion.Item>
<br />


<Accordion.Item eventKey="1">
  <Accordion.Header> <b className='accord-head'>2. Why Teach Financial Literacy? </b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
<ul>
  <li>Highlights the lack of financial education in traditional schooling systems.</li>
  <li>Advocates for self-education in financial matters to achieve success.</li>
  <li>Discusses the impact of fear and the desire for security on financial decision-making.</li>
  <li>Emphasizes the importance of learning about money through practical experience and real-world application.</li>
  <li>Stresses the role of entrepreneurship and investment in building wealth.</li>
</ul>
    </div>
  </Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="2">
  <Accordion.Header> <b className='accord-head'>3. Mind Your Own Business:</b></Accordion.Header>
  <Accordion.Body> 
  <div className="accord-box">
<ul>
  <li>Differentiates between assets and liabilities and their impact on financial health.</li>
  <li>Encourages readers to focus on building and acquiring assets that generate positive cash flow.</li>
  <li>Discusses the importance of developing and nurturing a business mindset.</li>
  <li>Emphasizes the need to understand financial statements and use them to make informed decisions.</li>
  <li>Highlights the concept of working to learn rather than working for money.</li>
</ul>
    </div>
  </Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="3">
  <Accordion.Header><b className='accord-head'>4. The History of Taxes and the Power of Corporations: </b> </Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
      <li>Discusses the historical context of taxes and their impact on wealth accumulation.</li>
      <li>Emphasizes the advantages of understanding and leveraging the tax code.</li>
      <li>Introduces the benefits of owning and operating businesses through corporations.</li>
      <li>Advocates for taking advantage of tax incentives and deductions available to entrepreneurs.</li>
      <li>Encourages readers to educate themselves about taxation to minimize financial losses.</li>
    </ul>
    </div>
  </Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="4">
  <Accordion.Header><b className='accord-head'>5. The Rich Invent Money:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
      <li> Explores the concept of financial innovation and the ability to create money.</li>
      <li>Highlights the difference between working for money and having money work for you.</li>
      <li>Discusses the importance of creativity and entrepreneurship in wealth creation.</li>
      <li>Encourages readers to seek opportunities and solve problems to generate income.</li>
      <li>Emphasizes the value of continuous learning and adaptability in the dynamic world of finance.</li>
    </ul>

    </div>
  </Accordion.Body>
</Accordion.Item>

<br />
<Accordion.Item eventKey="5">
  <Accordion.Header><b className='accord-head'>6. Work to Learn- Don't Work for Money: </b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
    <li>Advocates for a mindset focused on learning and skill development.</li>
    <li>Emphasizes the value of acquiring practical skills and experience.</li>
    <li>Discusses the limitations of job security and the potential for personal growth through challenges.</li>
    <li>Encourages readers to view mistakes and failures as opportunities for learning.</li>
    <li>Highlights the importance of expanding one's knowledge and skills to increase earning potential.</li>
    <li>The Poor and the Middle class work for Money. The Rich have Money Work for Them. (Means money became thier employees).</li>
    </ul>
    </div>
  </Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="6">
  <Accordion.Header> <b className='accord-head'>7. Overcoming Obstacles: </b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
      <li>Discusses common obstacles and challenges on the path to financial success.</li>
      <li>Advocates for resilience and the ability to turn setbacks into opportunities.</li>
      <li>Emphasizes the importance of facing fears and taking calculated risks.</li>
      <li>Highlights the role of attitude and mindset in overcoming obstacles.</li>
      <li>Identifying your roadblocks: Fear, laziness, cynicism, bad habits, and arrogance are common financial success obstacles.</li>
    </ul>
    </div>
  </Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="7">
  <Accordion.Header> <b className='accord-head'>8. Getting Started:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
    <ul>
      <li><b>Taking the first step:</b>  Start small, set achievable goals, and build momentum in your financial journey.</li>
      <li><b>Creating a budget and tracking expenses:</b> Understand your income and spending patterns to make informed financial decisions.</li>
      <li><b>Investing in yourself:</b> Continuously learn new skills and update your financial knowledge through books, courses, and mentors.</li>
      <li><b>Starting your own business:</b> Consider side hustles, small ventures, or real estate investments to build income-generating assets.</li>
      <li><b>Seeking professional guidance: </b>Seek financial advice from qualified professionals when needed.</li>
    </ul>

    </div>
  </Accordion.Body>
</Accordion.Item>





<br />
<Accordion.Item eventKey="8">
  <Accordion.Header> <b className='accord-head'>9. Still I Want More?</b></Accordion.Header>
  <Accordion.Body>

  <div className="accord-box">
    <ul>
      <li><b>Setting Bigger Goals:</b> Never settle, push yourself to achieve more than you initially thought possible.</li>
      <li><b>Continuous Learning and Expansion:</b> Dedicate yourself to lifelong learning and expanding your financial knowledge.</li>
      <li><b>Financial Mentorship:</b> Seek guidance from experienced mentors and entrepreneurs who have achieved financial success.</li>
      <li><b>Giving Back and Empowering Others:</b> Share your knowledge and help others achieve financial freedom.</li>
      <li><b>Financial Legacy:</b> Create a plan to leave a positive financial legacy for future generations.</li>
    </ul>
  
    </div>
  </Accordion.Body>
</Accordion.Item>





<br />
<Accordion.Item eventKey="14">
  <Accordion.Header> <b className='accord-head'>10. Timeless Lessons from This Book:</b></Accordion.Header>
  <Accordion.Body>
  <div className="accord-box">
  <ul>
    <li>Understand the Difference between <b>Assets</b> (Puts money in your pocket) and <b>Liabilities</b> (Takes money out of your pockets) in Our Life. How People make their Fortune by understanding these basic differences. Rich people aquire Assets (Get Richer). The Poor and Middle class acquire Libilities that they think are Assets.</li> <br />
    <li>It's not how much money you make. It's how much money you keep (Learn Financial Literacy). Know how to handle the money you have. Cash Flow- tells the story of how a person handles money. (Know: Cash Flow and Balance sheets).</li> <br />
    <li>A Person can be highly educated, professionally successful, and financially Illiterate.</li> <br />
    <li>Surround yourself with the Intelligent people and learn from them.</li> <br />
    <li>The Rich focus on their Asset Columns while everyone else focuses on their Income Statements.</li> <br />
    <li>Financial struggle is often the result of people working all their lives for someone else. Choose Fastlane (Build a Business System- Asset).</li> <br />

    <li>The Single most powerful asset we all have is Our Mind. If it is trained well, it can create enormous wealth. 
    Work to Learn (Learning is very important)- don't work for money.</li> <br />
    <li>The primary difference between a rich person and a poor person is how they manage Fear. Failure inspires winners. Failure defeats losers.</li>
  </ul>
    </div>
  </Accordion.Body>
</Accordion.Item>

<br />

<Accordion.Item eventKey="55">
<Accordion.Header> <b className='accord-head'>Important Notes: Growth Mindset...!  </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ul>
  <li>Above Mentioned Timeless Lessions can Change Your Life, If You Practice It in Daily Lives.</li>
  <br />
  <li>These are just summaries. Reading the book in its entirety will provide a valuable insights and actionable advice.</li>
  <br />
  <li><b>Visit Daily and Read Valuable Contents to Push Your Mental-Limit.</b></li>
</ul>
</div>
</Accordion.Body>
<br />
</Accordion.Item>

</Accordion>

<hr className="separator" />

<div className="autobox">
  <button className="buybotton"> <a href="https://amzn.to/3TgXDug">Check: Buy</a></button>
</div>

<br />
</div>



      <hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


<div className="relatedposts">
<h3>Related Posts</h3>
<ol>
<li><Link className={"link-styles"} to="/Ikigaibybyfrancescmirallesandhectorgarcia">Ikigai by by Francesc Miralles and Hector Garcia </Link></li>
<li><Link className={"link-styles"} to="/The7habitsofhighlyeffectivepeoplebystephenrcovey">The 7 Habits of Highly Effective People by Stephen R. Covey.  </Link></li>
<li><Link className={"link-styles"} to="/Thinkandgrowrichbynapoleonhill">Think and Grow Rich by Napoleon Hill</Link> </li>
<br />
<li><Link className={"link-styles"} to="/Youcanwinbyshivkhera">You Can Win by Shiv Khera </Link></li>

<li><Link className={"link-styles"} to="/Therichestmaninbabylonbygeorgesclason">The Richest Man in Babylon by George S. Clason  </Link></li>
</ol>
</div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Richdadpoordadbyrobertkiyosaki;


