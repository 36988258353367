// import React from 'react';
import React, { useState } from 'react';
import Footer from './Footer';
import Whatsappchat from '../component/Whatsappchat';

const About = () => {


//  WhatsApp for Others
   // const [name, setName] = useState('');
   // const handleSend = () => {
   //   const whatsappUrl = `https://api.whatsapp.com/send?phone=+917005818075&text=${encodeURIComponent(`Kindly Type Your Name: ${name}`)}`;
   //   window.open(whatsappUrl, '_blank');
   // };


  return (
   

   <>



<div className="content-box" style={{
      padding: '5px',
      // border: 'solid 2px black',

}}>
<h1 style={{
      textAlign: 'center',
      margin: "10px",
      // border: 'solid 2px black',
      
      

}
 
}> <b>About Us</b>  </h1>
This website (www.gumbaja.com) is owned and operated by a Team led by Gummas Baja. <br />

<b>Site Contents are: </b>

<ol>
   <li>Products Reviews</li>
   <li>Study: Basic Notes</li>
   <li>GK and Trending News</li>
   <li>Qoutes & Guides</li>
   <li>Book summary</li>
   <li>Job Updates</li>
</ol>

<br />
We are here to provide you quality content (reviews) to become a part of your decision making and growth.
</div>

<br /><br />

<hr className="separator"/>



<div className="content-box" style={{
      padding: '5px',
      // border: 'solid 2px black',

}}>


<h1 style={{
      textAlign: 'center',
      margin: "10px",
      // border: 'solid 2px black',

}
 
}> <b>Contact Us</b>  </h1>

If there is any error or need updation, you can contact us. <br />


You can give your own contents to publish in our website. <br /> <br />

<b>You can contact us by following means:</b> <br />
<ol>
<li>Call/WhatsApp: 07005369425  </li>
                                        {/* Wahtsapp Chat For My Contact*/}
<Whatsappchat/>
<br />

<li>WhatsApp Group: <a href="https://chat.whatsapp.com/EHZC9jtiDtbCt3MsJwArNG">Click Here</a></li>

<br />







                {/* WhatsApp Chat for */}
{/* <div className="autobox">
      <button className='whatsappchat' onClick={handleSend}>Send WhatsApp</button>
    </div> */}




<li>Follow WhatsApp Channel: <a href="https://whatsapp.com/channel/0029Val5Qj1EKyZFje1gXx21">Click Here </a></li>
              <br />
<li>Telegram Channel: <a href="https://t.me/gumbaja29">Click here</a></li>
<li><a href="">Facebook</a></li>
<li><a href="">Instagram</a></li>
<li><a href="">YouTube</a></li>


</ol>



<br />

<b>
Thank You <br />
Visit again <br />
(GumBaja)
</b>
</div>
<br /><br /> 
   <Footer/>
   </>
)
}

export default About
