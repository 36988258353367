import React from 'react'
import { Outlet, Link } from "react-router-dom";

import Reviewslider from './reviewslider.jsx/Reviewslider';

import WhatsAppShareButton from '../component/WhatsAppShareButton';
import Footer from '../pages/Footer';

//  Start from Here

const Review = () => {
  return (
<>
{/* Slider */}
<hr className="separator"/>

<Reviewslider/>

<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b> Best Reviews </b></h2>

<hr className="separator" />

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Best Mobile and Tabs: Review</h3>
<ol>
  <li><Link className={"link-styles"} to="/Bestratedmobiles">Best Rated Mobiles</Link></li>
  <li><Link className={"link-styles"} to="">Latest Launched Mobile in 2024 </Link></li>
  <li><Link className={"link-styles"} to="">Best Mobile under ₹15,000 </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Best Mobile between ₹15,001 to ₹30,000 </Link></li>
  <li><Link className={"link-styles"} to="">Best Mobile between ₹30,001 to ₹60,000 </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Best Tabs for Students (Study Purpose) </Link></li>
  <li><Link className={"link-styles"} to="">Best Tablets: High Performances </Link></li>
  </ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Best Earphone and Speakers: Review</h3>
<ol>
<li><Link className={"link-styles"} to="/Bestratedearbuds">Best Rated Bluetooth: Earbuds </Link></li>
<br /><br />
  <li><Link className={"link-styles"} to="">Best Rated Bluetooth: Headphones </Link></li>
  
  <li><Link className={"link-styles"} to="">Best Rated Bluetooth: Neckband </Link></li>
  <li><Link className={"link-styles"} to="">Best Rated Bluetooth: Small Speakers </Link></li>
  <li><Link className={"link-styles"} to="">Best Rated Bluetooth: Big Speakers </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
  </ol>

<br />
</div>
</div>
<hr className="separator" />










                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Best Laptop and Accessories: Review</h3>
 <ol>
  <li><Link className={"link-styles"} to="">Best Rated Laptops: For Office Use. </Link></li>
  <li><Link className={"link-styles"} to="">Best Rated Laptops: High Performances (Coding, Video Editing) </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Best Rated Laptops: Gaming Laptops </Link></li>
  <li><Link className={"link-styles"} to="">Best Rated: Mouse </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Best Rated: Keyboards </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
  </ol>


</div>




<div className="sbox1 part2">
<h3 className='topic'>Best Smart TV: Review</h3>
<ol>
  <li><Link className={"link-styles"} to="">Best Rated Smart TVs: Upto 40 Inches. </Link></li>
  <li><Link className={"link-styles"} to="">Best Rated Smart TVs: upto 50 Inches </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Best Rated Smart TVs: Above 50 Inches </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
  </ol>

</div>
</div>


<hr className="separator" />







                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Smart Gadgets</h3>
<ol>
  <li><Link className={"link-styles"} to="">Best Rated: Smart Watches </Link></li>
  <li><Link className={"link-styles"} to="">Best Rated: Digital Camera </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Best Rated: Mic for Vlogs </Link></li>

  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Home Appliances</h3>
<ol>
<li><Link className={"link-styles"} to="">Best Rated: Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Best Rated: Coming Soon </Link></li>
  <br />
  {/* <li><Link className={"link-styles"} to="/Formatreview">Best Rated: Format for 1</Link></li> */}

  {/* <li><Link className={"link-styles"} to="/Formatreview2">Best Rated: Format for 2+</Link></li> */}
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>
</div>
</div>


<hr className="separator" />


<WhatsAppShareButton/>
    <hr className="separator" />
    <br/>
    <Footer/>

    <Outlet />
</>
  )
}

export default Review

