import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


// Home
// Reviews
// Learnings
// Jobs
// Subject - Wise




// Home
import Learnings from "./leranings/Learnings";
import Review from "./review/Review";
import Jobs from "./jobs/Jobs"
import Latestposts from "./common/Latestposts";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About"
import Terms from "./pages/Terms";
import Disclaimer from "./pages/Disclaimer";

// Reviews
import Dummypost from "./formatall/Dummypost";
import Formatreview from "./formatall/Formatreview";
import Formatreview2 from "./formatall/Formatreview2";


import Bestratedmobiles from "./review/Bestratedmobiles";
import Bestratedearbuds from "./review/Bestratedearbuds";

import Bestratedneckband from "./review/Bestratedearbuds";




// Learnings

import Bsformat from "./leranings/Bsformat";
// Financial
import Thinkandgrowrichbynapoleonhill from "./leranings/financial/Thinkandgrowrichbynapoleonhill";

import Richdadpoordadbyrobertkiyosaki from "./leranings/financial/Richdadpoordadbyrobertkiyosaki";
import Youcanwinbyshivkhera from "./leranings/personal/Youcanwinbyshivkhera";

import Therichestmaninbabylonbygeorgesclason from "./leranings/financial/Therichestmaninbabylonbygeorgesclason";
import Thepsychologyofmoneybymorganhousel from "./leranings/financial/Thepsychologyofmoneybymorganhousel";
import Thecompoundeffectbydarrenhardy from "./leranings/financial/Thecompoundeffectbydarrenhardy";
import Thealchemistbypaulocoelho from "./leranings/financial/Thealchemistbypaulocoelho";


// Personal
import Ikigaibybyfrancescmirallesandhectorgarcia from "./leranings/personal/Ikigaibybyfrancescmirallesandhectorgarcia";
import The7habitsofhighlyeffectivepeoplebystephenrcovey from "./leranings/personal/The7habitsofhighlyeffectivepeoplebystephenrcovey";
import Howtowinfriendsandinfluencepeoplebydalecarnegie from "./leranings/personal/Howtowinfriendsandinfluencepeoplebydalecarnegie";











              // Jobs
import Jobformat from "./formatall/Jobformat";
import Nabardgradea from "./jobs/Nabardgradea";
import Ibpspoform2024 from "./jobs/Ibpspoform2024";
import Irdaiassistantmanagerform2024 from "./jobs/Irdaiassistantmanagerform2024";
import Nainitalbankpoandotherposts from "./jobs/Nainitalbankpoandotherposts";



// Subject - Wise
import Iastoppersandsyllabus from "./cse/Iastoppersandsyllabus"

// History
import History from "./history/History";

// Geography
import Geography from "./geography/Geography";

// PSIR
import Psir from "./psir/Psir";


// Economy
import Economy from "./economy/Economy";

//Environment
import Environment from "./environment/Environment";


// Science and Tech
import Science from "./science/Science";


// Security
import Security from "./security/Security";




// English

import English from "./english/English";
import Grammarthesentences from "./english/Grammarthesentences";
import Grammarpartsofspeech from "./english/Grammarpartsofspeech";
import Grammarthenoun from "./english/Grammarthenoun";
import Grammarpronoun from "./english/Grammarpronoun";
import Grammartheadjectives from "./english/Grammartheadjectives";
import Grammartheverb from "./english/Grammartheverb";
import Grammartheadverb from "./english/Grammartheadverb";
import Grammartenses from "./english/Grammartenses";
import Grammararticle from "./english/Grammararticle";
import Grammarthepreposition from "./english/Grammarthepreposition";
import Grammartheconjunction from "./english/Grammartheconjunction";
import Grammarinterjection from "./english/Grammarinterjection";
import Grammaractiveandpassivevoice from "./english/Grammaractiveandpassivevoice";
import Grammardirectandindirectspeech from "./english/Grammardirectandindirectspeech";
import Grammarantonymsandsynonyms from "./english/Grammarantonymsandsynonyms";
import Grammaronewordsubstitution from "./english/Grammaronewordsubstitution";
import Grammaridiomsandphrases from "./english/Grammaridiomsandphrases";
import Grammarrootwords from "./english/Grammarrootwords";




                     //    Function starts from here

export default function App() {
  return (
    <BrowserRouter>
      <Routes> 
                      {/* Navbar */}
<Route path="/" element={<Layout />}>
<Route index element={<Home />} />
<Route path="learnings" element={<Learnings />} />
<Route path="review" element={<Review/>} />
<Route path="jobs" element={<Jobs/>} />
<Route path="latestposts" element={<Latestposts/>} />

          
          
         
          
                         {/* Reviews */}
<Route path="formatreview" element={<Formatreview />} />

<Route path="dummypost" element={<Dummypost />} />
<Route path="formatreview" element={<Formatreview />} />
<Route path="formatreview2" element={<Formatreview2 />} />

<Route path="bestratedneckband" element={<Bestratedneckband />} />
<Route path="bestratedearbuds" element={<Bestratedearbuds />} />
<Route path="bestratedmobiles" element={<Bestratedmobiles />} />












{/* Learnings */}

<Route path="bsformat" element={<Bsformat />} />

<Route path="thinkandgrowrichbynapoleonhill" element={<Thinkandgrowrichbynapoleonhill />} />
<Route path="richdadpoordadbyrobertkiyosaki" element={<Richdadpoordadbyrobertkiyosaki />} />
<Route path="youcanwinbyshivkhera" element={<Youcanwinbyshivkhera />} />
<Route path="therichestmaninbabylonbygeorgesclason" element={<Therichestmaninbabylonbygeorgesclason />} />
<Route path="thepsychologyofmoneybymorganhousel" element={<Thepsychologyofmoneybymorganhousel />} />
<Route path="thecompoundeffectbydarrenhardy" element={<Thecompoundeffectbydarrenhardy />} />
<Route path="thealchemistbypaulocoelho" element={<Thealchemistbypaulocoelho />} />

<Route path="ikigaibybyfrancescmirallesandhectorgarcia" element={<Ikigaibybyfrancescmirallesandhectorgarcia />} />

<Route path="the7habitsofhighlyeffectivepeoplebystephenrcovey" element={<The7habitsofhighlyeffectivepeoplebystephenrcovey />} />
<Route path="howtowinfriendsandinfluencepeoplebydalecarnegie" element={<Howtowinfriendsandinfluencepeoplebydalecarnegie />} />














{/* Jobs */}
<Route path="jobformat" element={<Jobformat />} />
<Route path="nabardgradea" element={<Nabardgradea />} />

<Route path="ibpspoform2024" element={<Ibpspoform2024 />} />
<Route path="irdaiassistantmanagerform2024" element={<Irdaiassistantmanagerform2024 />} />
<Route path="Nainitalbankpoandotherposts" element={<Nainitalbankpoandotherposts />} />

{/* <Route path="" element={< />} /> */}






{/* Subject - Wise */}
<Route path="iastoppersandsyllabus" element={<Iastoppersandsyllabus />} />

                {/* History */}
<Route path="history" element={<History />} />
{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}


               {/* Geography */}
<Route path="geography" element={<Geography />} />
{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}


               {/* PSIR */}
<Route path="psir" element={<Psir />} />
{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}


              {/* Econiomy */}
<Route path="economy" element={<Economy />} />

{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}



              {/* Environment */}
<Route path="environment" element={<Environment />} />
{/* <Route path="" element={< />} /> */}


          {/* Science and Tech */}
<Route path="science" element={<Science />} />
{/* <Route path="" element={< />} /> */}


            {/* Security */}
<Route path="security" element={<Security />} />
{/* <Route path="" element={< />} /> */}


             {/* English */}
<Route path="english" element={<English />} />
<Route path="grammarthesentences" element={<Grammarthesentences />} />
<Route path="grammarthenoun" element={<Grammarthenoun />} />
<Route path="grammarpronoun" element={<Grammarpronoun />} />
<Route path="grammartheadjectives" element={<Grammartheadjectives />} />
<Route path="grammartheverb" element={<Grammartheverb />} />
<Route path="grammartheadverb" element={<Grammartheadverb />} />
<Route path="grammartenses" element={<Grammartenses />} />
<Route path="grammararticle" element={<Grammararticle />} />
<Route path="grammarthepreposition" element={<Grammarthepreposition />} />
<Route path="grammartheconjunction" element={<Grammartheconjunction />} />
<Route path="grammarinterjection" element={<Grammarinterjection />} />
<Route path="Grammaractiveandpassivevoice" element={<Grammaractiveandpassivevoice />} /> 
<Route path="grammardirectandindirectspeech" element={<Grammardirectandindirectspeech />} />
<Route path="grammarpartsofspeech" element={<Grammarpartsofspeech />} />
<Route path="grammarantonymsandsynonyms" element={<Grammarantonymsandsynonyms />} />
<Route path="grammaronewordsubstitution" element={<Grammaronewordsubstitution />} />
<Route path="grammaridiomsandphrases" element={<Grammaridiomsandphrases />} />
<Route path="grammarrootwords" element={<Grammarrootwords />} />

{/* <Route path="" element={< />} /> */}
{/* <Route path="" element={< />} /> */}




          {/* <Route path="health" element={<Health />} />
          <Route path="tourism" element={<Tourism />} /> */}
          {/* <Route path="learnings" element={<Learnings />} /> */}

          {/* Home */}
          {/* <Route path="" element={< />} /> */}
        

          {/* <Route path="formatclosed" element={<Formatclosed />} /> */}


          {/* Learnings */}


          {/* Education */}

          
          {/* Health */}
             

          {/* Footer */}

          <Route path="about" element={<About/>} />
        
          <Route path="terms" element={<Terms />} />
          <Route path="disclaimer" element={<Disclaimer/>} />
          

          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);