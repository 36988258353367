import React from 'react'
import { Outlet, Link } from "react-router-dom";
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import Footer from '../pages/Footer';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from 'react-helmet';



const Iastoppersandsyllabus = () => {
  return (
     <>

<Helmet>
        <title>IAS Syllabus GumBaja</title>
        <meta name="description" content="Page description" />
        <link rel="icon" href="%PUBLIC_URL%/gumbaja.png" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>



                             {/* Slider */}
    <hr className="separator"/>
<h3 className="latest-post" style={{color: "green"}}> <b>IAS Toppers </b> </h3>

<hr className="separator" />


{/* Ias Toppers */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'><b>UPSC CSE (IAS) Toppers: 2019-2023</b></h3>
<ol>
  <li>IAS/IPS Toppers 2023:<Link className={"link-styles"} to=" "> Aditya Srivastava (AIR 1) </Link></li>
  <li>IAS/IPS Toppers 2022:<Link className={"link-styles"} to=" "> Ishita Kishore (AIR 1)  </Link></li>
  <li> IAS/IPS Toppers 2021:<Link className={"link-styles"} to=" "> Shruti Sharma (AIR 1)</Link> </li>
  <li>IAS/IPS Toppers 2020:<Link className={"link-styles"} to=" "> Shubham Kumar (AIR 1)  </Link></li>

</ol>
</div>




<div className="sbox1 part2">
  <h3 className='topic'><b>Officers in News </b></h3>
<ol>
  <li><Link className={"link-styles"} to=""></Link> </li>
  <li><Link className={"link-styles"} to=""></Link> </li>
  <li><Link className={"link-styles"} to=""></Link> </li>
  <li><Link className={"link-styles"} to=""></Link> </li>
  {/* <li><Link className={"link-styles"} to="/Learnings"></Link> </li> */}

  
</ol>

</div>
</div>

<br />

               {/* Prelims Syllabus */}
<hr className="separator"/>
<h4 className="latest-post" style={{color: "green"}}> <b>UPSC CSE (IAS): Prelims Syllabus</b> </h4>

<hr className="separator" />



                           {/* Prelims Syllabus */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'><b>GS: Syllabus</b></h3>
  
<ol>
  <li><Link className={"link-styles"} to="">History of India and Indian National Movement. </Link></li>
  <li><Link className={"link-styles"} to="">Indian and World Geography- Physical, Social, Economic Geography of India and the World. </Link></li>
  <li><Link className={"link-styles"} to="">Indian Polity and Governance – Constitution, Political System, Panchayati Raj, Public Policy, Rights Issues, etc. </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Economic and Social Development – Sustainable Development, Poverty, Inclusion, Demographics, Social Sector initiatives, etc. </Link></li>
  <li><Link className={"link-styles"} to="">General issues on Environmental Ecology, Biodiversity, and Climate Change – that do not require subject specialisation </Link></li>
  <li><Link className={"link-styles"} to="">General Science. </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Current events of national and international importance. </Link></li>
</ol>
{/* 
<br />
<div>
  <Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='topic'>Books:</h3>  - (Click Here)</Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <ol>
          <h3 className='recomm'>Toppers' Recommended:</h3>
              <li>History: </li> <br />
              <li>Geography: </li> <br />
              <li>Polity: </li> <br />
              <li>Economy:</li> <br />
              <li>Environment: </li><br />
              <li>Science and Tech: </li>
          </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />
  </Accordion>
</div> */}

</div>




<div className="sbox1 part2">
<h3 className='topic'><b>CSAT: Syllabus</b> </h3>
<ol>

  <li><Link className={"link-styles"} to="">Comprehension (Strong Vocabulary + Reading speed).</Link></li>
  <li><Link className={"link-styles"} to="">Interpersonal skills including communication skills. </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Logical reasoning and analytical ability. </Link></li>
  <li><Link className={"link-styles"} to="">Decision-making and problem solving. </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">General mental ability. </Link></li>
  <li><Link className={"link-styles"} to="">Basic numeracy (numbers and their relations, orders of magnitude, etc.) (Class X level),
  Data interpretation (charts, graphs, tables, data sufficiency, etc. – Class X level) </Link></li>
  {/* <li><Link className={"link-styles"} to="/"> </Link></li> */}
  <br />
  {/* <li><Link className={"link-styles"} to="/Bsformat">Format </Link></li> */}
</ol>


<div>
  <Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='topic'>Books:</h3>  - (Click Here)</Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <ol>
          <h3 className='recomm'>Toppers' Recommended:</h3>
              <li>CSAT Book: <a href="https://amzn.to/4dxmPVo">Click Here</a></li> <br />
          </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
  </Accordion>
</div>

</div>
</div>






                      {/* Mains Syllabus */}


<hr className="separator" />

<h4 className="latest-post" style={{color: "green"}}> <b>UPSC CSE (IAS): Mains Syllabus</b> </h4>

<hr className="separator" />



                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'><b>GS-I: Syallabus</b></h3>
  
<ol>
  <li><Link className={"link-styles"} to="">Indian culture will cover the salient aspects of Art Forms, Literature and Architecture from ancient to modern times. </Link></li>
  <li><Link className={"link-styles"} to="">Modern Indian history from about the middle of the eighteenth century until the present- significant events, personalities, issues. </Link></li>
  <li><Link className={"link-styles"} to="">The Freedom Struggle- its various stages and important contributors /contributions from different parts of the country. </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Post-independence consolidation and reorganization within the country. </Link></li>
  <li><Link className={"link-styles"} to="">History of the world will include events from 18th century such as industrial revolution, world wars, redrawing of national boundaries, colonization, decolonization, political philosophies like communism, capitalism, socialism etc.- their forms and effect on the society. </Link></li>
  <br />
  <br />
  <li><Link className={"link-styles"} to="">Salient features of Indian Society, Diversity of India. </Link></li>
  <li><Link className={"link-styles"} to="">Role of women and women's organization, population and associated issues, poverty and developmental issues, urbanization, their problems and their remedies. </Link></li>
  <li><Link className={"link-styles"} to="">Effects of globalization on Indian society. </Link></li>
  <li><Link className={"link-styles"} to="">Social Empowerment, communalism, regionalism & secularism. </Link></li>
  <br />
  <br />
  <li><Link className={"link-styles"} to="">Salient features of world's physical geography. </Link></li>
  <li><Link className={"link-styles"} to="">Distribution of key natural resources across the world (including South Asia and the Indian sub-continent); <br />
  Factors responsible for the location of primary, secondary, and tertiary sector industries in various parts of the world (including India). </Link></li>
  <li><Link className={"link-styles"} to="">Important Geophysical phenomena such as earthquakes, Tsunami, Volcanic activity, cyclone etc.,<br />
  Geographical features and their location: changes in critical geographical features (including water-bodies and ice-caps) and in flora and fauna and the effects of such changes. </Link></li>
  <br />
</ol>

<div>
  <Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='topic'>Books:</h3>  - (Click Here)</Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">

    <h3 className='recomm'>Toppers' Recommended: History</h3>
          <ol>
        <li>Ancient and Medieval: <a href="https://amzn.to/4fSc9lM">Tamil Nadu Board (Class 11th) </a></li>
        <li>Modern History: <a href="https://amzn.to/4dogvPX"> A Brief History of Modern India by Rajiv Ahir (Spectrum) </a></li> <br />

        <li>Indian Art and Culture: <a href="https://amzn.to/3WY6wty"> by Nitin Singhania</a> </li>
    <li>Society: </li> <br /> 

      <li>World History: <a href="https://amzn.to/4dyiQHZ">Contemporary World History: NCERT Class 9th</a></li>
         </ol>

    <br />
        


    <h3 className='recomm'>Toppers' Recommended: Geography:</h3>
    <ol>
          <li>Physical Geography: <a href="https://amzn.to/3SZHjxX">Click Here</a></li>
          <li>Economic Geography: <a href="https://amzn.to/4fQTBSX">Click Here</a></li>
          <li>Human Geography: <a href="https://amzn.to/3WPEMY6">Click Here</a></li>
          <li>Indian Geography: <a href="https://amzn.to/4dv6fW1">Click Here</a></li>
          <br />
          <li>World Geography: <a href="https://amzn.to/4cRcOBr">Click Here</a></li>
          <li>World Map: <a href="https://amzn.to/4dRpxVA">Click Here</a></li>

      </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />
  </Accordion>
</div>

</div>




<div className="sbox1 part2">
<h3 className='topic'><b>GS-II: Syllabus</b> </h3>
<ol>
<li><Link className={"link-styles"} to="">Indian Constitution- historical underpinnings, evolution, features, amendments, significant provisions and basic structure. </Link></li>
  <li><Link className={"link-styles"} to="">Functions and responsibilities of the Union and the States, issues and challenges pertaining to the federal structure, devolution of powers and finances up to local levels and challenges therein. </Link></li>
  <li><Link className={"link-styles"} to="">Separation of powers between various organs dispute redressal mechanisms and institutions. </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Comparison of the Indian constitutional scheme with that of other countries. </Link></li>
  <li><Link className={"link-styles"} to="">Parliament and State Legislatures– structure, functioning, conduct of business, powers & privileges and issues arising out of these. </Link></li>
  <li><Link className={"link-styles"} to="">Structure, organization and functioning of the Executive and the Judiciary -Ministries and Departments of the Government. Pressure Groups and formal/informal associations and their role in the Polity. </Link></li>
  <br />


  <li><Link className={"link-styles"} to="">Salient features of the Representation of People’s Act. </Link></li>
  <li><Link className={"link-styles"} to="">Appointment to various Constitutional posts, powers, functions and responsibilities of various Constitutional Bodies. </Link></li>
  <li><Link className={"link-styles"} to="">Statutory, regulatory and various quasi-judicial bodies </Link></li>
  <br />


  <li><Link className={"link-styles"} to="">Government policies and interventions for development in various sectors and issues arising out of their design and implementation. </Link></li>
  <li><Link className={"link-styles"} to="">Development processes and the development industry- the role of NGOs, SHGs, various groups and associations, donors, charities, institutional and other stakeholders. </Link></li>
  <li><Link className={"link-styles"} to="">Important aspects of governance, transparency and accountability, e-governance: applications, models, successes, limitations and potential, citizens charters, transparency & accountability and institutional and other measures. </Link></li>
  
  <li><Link className={"link-styles"} to="">Role of civil services in a democracy. </Link></li>
  <br />
  <br />
  <li><Link className={"link-styles"} to="">Welfare schemes for vulnerable sections of the population by the Centre and States and the performance of these schemes; mechanisms, laws, institutions and Bodies constituted for the protection and betterment of these vulnerable sections. </Link></li>

  <li><Link className={"link-styles"} to="">Issues relating to development and management of Social Sector/Services relating to Health, Education, Human Resources. </Link></li>

  <li><Link className={"link-styles"} to="">Issues relating to poverty and hunger. </Link></li>
  <br />

  <br />


  <li><Link className={"link-styles"} to="">India and its neighbourhood- relations. </Link></li>
  <li><Link className={"link-styles"} to="">Bilateral, regional and global groupings and agree ments involving India and/or affecting India’s interests. </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Effect of policies and politics of developed and developing countries on India’s interests, Indian Diaspora. </Link></li>
  <li><Link className={"link-styles"} to="">Important International institutions, agencies and fora- their structure, mandate. </Link></li>
  {/* <li><Link className={"link-styles"} to=""> </Link></li> */}

  <br />

  {/* <li><Link className={"link-styles"} to="/Bsformat">Format </Link></li> */}
</ol>


<div>
<Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='topic'>Books:</h3>  - (Click Here)</Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <ol>
          <h3 className='recomm'>Toppers' Recommended:</h3>
             <li>Polity: <a href="https://amzn.to/3YVkNtJ">Lakshmikant</a></li> 
             <li>Governance: <a href="https://amzn.to/4dCYL3p">Click Here</a></li> <br />
             <li>IR Book: <a href="https://amzn.to/3SYbRQD">Click Here</a></li> <br />
             
  </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />
</Accordion>
</div>

</div>
</div>

<br />





<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'><b>GS-III: Syallabus</b></h3>
  
<ol>
  <li><Link className={"link-styles"} to="">Indian Economy and issues relating to planning, mobilization of resources, growth, development and employment. </Link></li>
  <li><Link className={"link-styles"} to="">Inclusive growth and issues arising from it. </Link></li>
  <li><Link className={"link-styles"} to="">Government Budgeting. </Link></li>
  <br />

  <li><Link className={"link-styles"} to="">Major crops cropping patterns in various parts of the country, different types of irrigation and irrigation systems storage, transport and marketing of agricultural produce and issues and related constraints; e-technology in the aid of farmers. </Link></li>
  <li><Link className={"link-styles"} to="">Issues related to direct and indirect farm subsidies and minimum support prices; Public Distribution System- objectives, functioning, limitations, revamping, issues of buffer stocks and food security; Technology missions; economics of animal-rearing. </Link></li>
  <li><Link className={"link-styles"} to="">Food processing and related industries in India- scope and significance, location, upstream and downstream requirements, supply chain management. </Link></li>
  <br />

  <li><Link className={"link-styles"} to="">Land reforms in India. </Link></li>
  <li><Link className={"link-styles"} to="">Effects of liberalization on the economy, changes in industrial policy and their effects on Industrial growth. </Link></li>
  <li><Link className={"link-styles"} to="">Infrastructure: Energy, Ports, Roads, Airports, Railways etc. </Link></li>
  <li><Link className={"link-styles"} to="">Investment models. </Link></li>
  <br />


  <li><Link className={"link-styles"} to="">Science and Technology- developments and their applications and effects in everyday life. </Link></li>
  <li><Link className={"link-styles"} to="">Achievements of Indians in science & technology; indigenization of technology and developing new technology. </Link></li>
  <li><Link className={"link-styles"} to="">Awareness in the fields of IT, Space, Computers, robotics, nano-technology, bio-technology and issues relating to intellectual property rights. </Link></li>

  <br />

  <li><Link className={"link-styles"} to="">Conservation, environmental pollution and degradation, environmental impact assessment. </Link></li>
  <li><Link className={"link-styles"} to="">Disaster and disaster management. </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Linkages between development and spread of extremism. </Link></li>
  <li><Link className={"link-styles"} to="">Role of external state and non-state actors in creating challenges to internal security. </Link></li>
  <li><Link className={"link-styles"} to="">Challenges to internal security through communication networks, role of media and social networking sites in internal security challenges, basics of cyber security; money-laundering and its prevention. </Link></li>
  <li><Link className={"link-styles"} to="">Security challenges and their management in border areas; linkages of organized crime with terrorism. </Link></li>
  <li><Link className={"link-styles"} to="">Various Security forces and agencies and their mandate. </Link></li>

  <br />

  {/* <li><Link className={"link-styles"} to="/Bsformat">Format </Link></li> */}

</ol>


<div>
<Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='topic'>Books:</h3>  - (Click Here)</Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <h3 className='recomm'>Toppers' Recommended:</h3>
              <ol>
              <li>Economy Book: <a href="https://amzn.to/4fWKYWO">Ramesh Singh</a></li> <br />
                <li>Environment: <a href="https://amzn.to/3MACeIR">Shankar IAS Academy</a></li> <br />
                <li>Science and Technology: <a href="https://amzn.to/3yKDhT7">Click Here</a></li><br />
                <li>Internal Security and Disaster Management Book: <a href="https://amzn.to/3YTmAPO">Click Here</a></li>
              </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />
</Accordion>
</div>

</div>




<div className="sbox1 part2">
<h3 className='topic'><b>GS-IV: Syallabus</b></h3>

<ol>
  <b>This paper will include questions to test the candidates’ attitude and approach to issues relating to integrity, probity in public life and his problem solving approach to various issues and conflicts faced by him in dealing with society. Questions may utilise the case study approach to determine these aspects. The following broad areas will be covered:</b> <br />
  <br />

  <li><Link className={"link-styles"} to="/"><b>Ethics and Human Interface:</b> Essence, Determinants and Consequences of Ethics in - Human Actions; Dimensions of Ethics; Ethics - in Private and Public Relationships.
  Human Values - Lessons from the Lives and Teachings of Great Leaders, Reformers and Administrators; Role of Family Society and Educational Institutions in Inculcating Values. </Link></li>

  <li><Link className={"link-styles"} to=""><b>Attitude:</b> Content, Structure, Function; its Influence and Relation with Thought and Behaviour; Moral and Political Attitudes; Social Influence and Persuasion. </Link></li>

  <li><Link className={"link-styles"} to=""><b>Aptitude and Foundational Values for Civil Service:</b> Integrity, Impartiality and Non-partisanship, Objectivity, Dedication to Public Service, Empathy, Tolerance and Compassion towards the weaker-sections. </Link></li>
  <br />
  <li><Link className={"link-styles"} to=""><b>Emotional Intelligence:</b> Concepts, and their Utilities and Application in Administration and Governance. </Link></li>

  <li><Link className={"link-styles"} to=""><b>Moral Thinkers and Philosophers:</b> Contributions of Moral Thinkers and Philosophers from India and World. </Link></li>

  <li><Link className={"link-styles"} to=""><b>Public/Civil Service Values and Ethics in Public Administration:</b> Status and Problems; Ethical Concerns and Dilemmas in Government and Private Institutions; Laws, Rules, Regulations and Conscience as Sources of Ethical Guidance; Accountability and Ethical Governance; Strengthening of Ethical and Moral Values in Governance; Ethical Issues in International Relations and Funding; Corporate Governance. </Link></li>
  <br />
  <li><Link className={"link-styles"} to=""><b>Probity in Governance:</b> Concept of Public Service; Philosophical Basis of Governance and Probity; Information Sharing and Transparency in Government, Right to Information, Codes of Ethics, Codes of Conduct, Citizen’s Charters, Work Culture, Quality of Service Delivery, Utilization of Public Funds, Challenges of Corruption. </Link></li>

  <li><Link className={"link-styles"} to=""><b>Case Studies:</b> on above issues. </Link></li>
  {/* <li><Link className={"link-styles"} to=""> </Link></li> */}
  <br />


  <br />

  {/* <li><Link className={"link-styles"} to="/Bsformat">Format </Link></li> */}
</ol>


<div>
<Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='topic'>Books:</h3>  - (Click Here)</Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <h3 className='recomm'>Toppers' Recommended:</h3>
              <ol>
                 <li>Ethics: <a href="https://amzn.to/4fUxaw8">Lexicon</a></li> <br />
              </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />
</Accordion>

</div>
</div>
</div>
<hr className="separator" />



<WhatsAppShareButton/>

    <hr className="separator" />

    <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/History">History </Link></li>
          <li><Link className={"link-styles"} to="/Geography">Geography </Link></li>
          <li><Link className={"link-styles"} to="/Psir">Polity </Link></li>
          <li><Link className={"link-styles"} to="/Economy">Economy </Link></li>
          <li><Link className={"link-styles"} to="/Environment">Environment </Link></li>
          <li><Link className={"link-styles"} to="/Science">Science and Technology </Link></li>
        </ol>
      </div>
      <hr className="separator" />






    <br/>
    <Footer/>
    <Outlet />
</>
  )
}

export default Iastoppersandsyllabus

