// import React from 'react';
import React, { useState, useEffect } from 'react';
// import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer';
import { Helmet } from 'react-helmet';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import WhatsAppShareButton from '../component/WhatsAppShareButton';


import Carousel from 'react-bootstrap/Carousel';
import { Outlet, Link } from "react-router-dom";





// npm install react-helmet

function Bestratedmobiles({ }) {
  return (
    <>
      <Helmet>
        <title>Top Rated (Flagship) Mobiles under ₹30,000: Reviews</title>
        <meta name="description" content="Top Rated (Flagship) Mobiles under ₹30,000: Reviews by GumBaja" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>

<hr className="separator"/>

<h3 className="latest-post" style={{color: "red", fontSize: ""}}> <b>Top Rated (Flagship) Mobiles under ₹30,000: Reviews </b> </h3>

<hr className="separator" />



                                {/* Review bbox */}

<div className="reviewbbox">
<br />
<h3 className='topic'><b>Mobile Reviews</b> </h3>

<ul>
  <li>These are the Top Rated (Best Budget) Mobile Phones of the 2024.</li>
  <li>These are Value For Money Phones in India with these Price:  September 2024</li>
  <li>We listed these mobile phones after reading/watching many user's review.</li>
</ul>
        
<br />

<Accordion>
<Accordion.Item eventKey="0">
<Accordion.Header> <b className='accord-head'> 1. Realme GT 6: </b> <span className='clickhere'> - Click Here</span></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
{/* <div className="accord-inbox"> */}


<Carousel data-bs-theme="dark">
{/* Slider 1 */}
<Carousel.Item>

<div className="carouselimage">
<a href="https://www.digitalarunachal.com/">
<img
// className="d-block w-100"
src="images/gumbaja.jpg"
alt="First slide"
/>
</a>

</div>

{/* <Carousel.Caption>
<h5>First slide label</h5>
<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
</Carousel.Caption> */}
</Carousel.Item>



{/* Slider 2 */}
<Carousel.Item>
<div className="carouselimage">
<Link to="/Jobs"> 
<img
src="images/gumbajam.png"
alt="Second slide"
/>
</Link>
</div>


{/* <Carousel.Caption>
<h5>Second slide label</h5>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</Carousel.Caption> */}
</Carousel.Item>

{/* Slider 3 */}
<Carousel.Item>
<div className="carouselimage">
<img
src="images/gumbaja.jpg"
alt="Third slide"
/>
</div>
{/* <Carousel.Caption>
<h5>Third slide label</h5>
<p>
Praesent commodo cursus magna, vel scelerisque nisl consectetur.
</p>
</Carousel.Caption> */}
</Carousel.Item>
</Carousel>
<br />





                  {/* Mobile Area */}


<h3 className='topic'><b>Mobile: Details</b> </h3>

<div className="mobiledetail">
<div className="mobilebox">
<li><b>Brand:</b> </li>
<li><b>Model:</b> </li>
<li><b>Color:</b> </li>
<li><b>Screen Size:</b> </li>
<li><b>Resolution:</b> </li>
<li><b>Display: </b></li>
<li><b>Camera: </b></li>
<li><b>Camera Modes:</b> </li>
</div>

<div className="mobilebox mbox2">
<li><b>Battery:</b> </li>
<li><b>Charging Type:</b> </li>
<li><b>Processor:</b></li>
<li><b>Operating System:</b></li>
<li><b>Android Version:</b> </li>
<li><b>Memory: </b></li>
<li><b>SIM Type:</b> </li>
<br />
<li><b>Launched Date: </b></li>
</div>

</div>




<div className="autobox">
<button className="buybotton"> <a href="">Check: Buy</a></button>
</div>
</div>
</Accordion.Body>
</Accordion.Item>
<br />






<Accordion.Item eventKey="1">
<Accordion.Header> <b className='accord-head'>2.OnePlus 12R </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
{/* <div className="accord-inbox"> */}



<br />





                  {/* Mobile Area */}


<h3 className='topic'><b>Mobile: Details</b> </h3>

<div className="mobiledetail">
<div className="mobilebox">
<li><b>Brand:</b> </li>
<li><b>Model:</b> </li>
<li><b>Color:</b> </li>
<li><b>Screen Size:</b> </li>
<li><b>Resolution:</b> </li>
<li><b>Display: </b></li>
<li><b>Camera: </b></li>
<li><b>Camera Modes:</b> </li>
</div>

<div className="mobilebox mbox2">
<li><b>Battery:</b> </li>
<li><b>Charging Type:</b> </li>
<li><b>Processor:</b></li>
<li><b>Operating System:</b></li>
<li><b>Android Version:</b> </li>
<li><b>Memory: </b></li>
<li><b>SIM Type:</b> </li>
<br />
<li><b>Launched Date: </b></li>
</div>

</div>




<div className="autobox">
<button className="buybotton"> <a href="">Check: Buy</a></button>
</div>
</div>
</Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="2">
<Accordion.Header> <b className='accord-head'>3. OnePlus 12 </b></Accordion.Header>
<Accordion.Body> 
<div className="accord-box">
{/* <div className="accord-inbox"> */}







                  {/* Mobile Area */}


<h3 className='topic'><b>Mobile: Details</b> </h3>

<div className="mobiledetail">
<div className="mobilebox">
<li><b>Brand:</b> </li>
<li><b>Model:</b> </li>
<li><b>Color:</b> </li>
<li><b>Screen Size:</b> </li>
<li><b>Resolution:</b> </li>
<li><b>Display: </b></li>
<li><b>Camera: </b></li>
<li><b>Camera Modes:</b> </li>
</div>

<div className="mobilebox mbox2">
<li><b>Battery:</b> </li>
<li><b>Charging Type:</b> </li>
<li><b>Processor:</b></li>
<li><b>Operating System:</b></li>
<li><b>Android Version:</b> </li>
<li><b>Memory: </b></li>
<li><b>SIM Type:</b> </li>
<br />
<li><b>Launched Date: </b></li>
</div>

</div>




<div className="autobox">
<button className="buybotton"> <a href="">Check: Buy</a></button>
</div>
</div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="3">
<Accordion.Header><b className='accord-head'>4. Oppo Reno 12 Pro </b> </Accordion.Header>
<Accordion.Body>

<div className="accord-box">
{/* <div className="accord-inbox"> */}



<br />





                  {/* Mobile Area */}


<h3 className='topic'><b>Mobile: Details</b> </h3>

<div className="mobiledetail">
<div className="mobilebox">
<li><b>Brand:</b> </li>
<li><b>Model:</b> </li>
<li><b>Color:</b> </li>
<li><b>Screen Size:</b> </li>
<li><b>Resolution:</b> </li>
<li><b>Display: </b></li>
<li><b>Camera: </b></li>
<li><b>Camera Modes:</b> </li>
</div>

<div className="mobilebox mbox2">
<li><b>Battery:</b> </li>
<li><b>Charging Type:</b> </li>
<li><b>Processor:</b></li>
<li><b>Operating System:</b></li>
<li><b>Android Version:</b> </li>
<li><b>Memory: </b></li>
<li><b>SIM Type:</b> </li>
<br />
<li><b>Launched Date: </b></li>
</div>

</div>




<div className="autobox">
<button className="buybotton"> <a href="">Check: Buy</a></button>
</div>
</div>
</Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="4">
<Accordion.Header><b className='accord-head'>5. Galaxy A55 </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
{/* <div className="accord-inbox"> */}







                  {/* Mobile Area */}


<h3 className='topic'><b>Mobile: Details</b> </h3>

<div className="mobiledetail">
<div className="mobilebox">
<li><b>Brand:</b> </li>
<li><b>Model:</b> </li>
<li><b>Color:</b> </li>
<li><b>Screen Size:</b> </li>
<li><b>Resolution:</b> </li>
<li><b>Display: </b></li>
<li><b>Camera: </b></li>
<li><b>Camera Modes:</b> </li>
</div>

<div className="mobilebox mbox2">
<li><b>Battery:</b> </li>
<li><b>Charging Type:</b> </li>
<li><b>Processor:</b></li>
<li><b>Operating System:</b></li>
<li><b>Android Version:</b> </li>
<li><b>Memory: </b></li>
<li><b>SIM Type:</b> </li>
<br />
<li><b>Launched Date: </b></li>
</div>

</div>




<div className="autobox">
<button className="buybotton"> <a href="">Check: Buy</a></button>
</div>
</div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="5">
<Accordion.Header><b className='accord-head'>6. Samsung S23 </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
{/* <div className="accord-inbox"> */}



<br />





                  {/* Mobile Area */}


<h3 className='topic'><b>Mobile: Details</b> </h3>

<div className="mobiledetail">
<div className="mobilebox">
<li><b>Brand:</b> </li>
<li><b>Model:</b> </li>
<li><b>Color:</b> </li>
<li><b>Screen Size:</b> </li>
<li><b>Resolution:</b> </li>
<li><b>Display: </b></li>
<li><b>Camera: </b></li>
<li><b>Camera Modes:</b> </li>
</div>

<div className="mobilebox mbox2">
<li><b>Battery:</b> </li>
<li><b>Charging Type:</b> </li>
<li><b>Processor:</b></li>
<li><b>Operating System:</b></li>
<li><b>Android Version:</b> </li>
<li><b>Memory: </b></li>
<li><b>SIM Type:</b> </li>
<br />
<li><b>Launched Date: </b></li>
</div>

</div>




<div className="autobox">
<button className="buybotton"> <a href="">Check: Buy</a></button>
</div>
</div>
</Accordion.Body>
</Accordion.Item>


<br />
<Accordion.Item eventKey="6">
<Accordion.Header> <b className='accord-head'>7. Samsung S23 FE</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
{/* <div className="accord-inbox"> */}


                  {/* Mobile Area */}


<h3 className='topic'><b>Mobile: Details</b> </h3>

<div className="mobiledetail">
<div className="mobilebox">
<li><b>Brand:</b> </li>
<li><b>Model:</b> </li>
<li><b>Color:</b> </li>
<li><b>Screen Size:</b> </li>
<li><b>Resolution:</b> </li>
<li><b>Display: </b></li>
<li><b>Camera: </b></li>
<li><b>Camera Modes:</b> </li>
</div>

<div className="mobilebox mbox2">
<li><b>Battery:</b> </li>
<li><b>Charging Type:</b> </li>
<li><b>Processor:</b></li>
<li><b>Operating System:</b></li>
<li><b>Android Version:</b> </li>
<li><b>Memory: </b></li>
<li><b>SIM Type:</b> </li>
<br />
<li><b>Launched Date: </b></li>
</div>

</div>




<div className="autobox">
<button className="buybotton"> <a href="">Check: Buy</a></button>
</div>
</div>
</Accordion.Body>
</Accordion.Item>



<br />
<Accordion.Item eventKey="7">
<Accordion.Header> <b className='accord-head'>8. Xiomi 14 Civi</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
{/* <div className="accord-inbox"> */}


                  {/* Mobile Area */}


<h3 className='topic'><b>Mobile: Details</b> </h3>

<div className="mobiledetail">
<div className="mobilebox">
<li><b>Brand:</b> </li>
<li><b>Model:</b> </li>
<li><b>Color:</b> </li>
<li><b>Screen Size:</b> </li>
<li><b>Resolution:</b> </li>
<li><b>Display: </b></li>
<li><b>Camera: </b></li>
<li><b>Camera Modes:</b> </li>
</div>

<div className="mobilebox mbox2">
<li><b>Battery:</b> </li>
<li><b>Charging Type:</b> </li>
<li><b>Processor:</b></li>
<li><b>Operating System:</b></li>
<li><b>Android Version:</b> </li>
<li><b>Memory: </b></li>
<li><b>SIM Type:</b> </li>
<br />
<li><b>Launched Date: </b></li>
</div>

</div>




<div className="autobox">
<button className="buybotton"> <a href="">Check: Buy</a></button>
</div>
</div>
</Accordion.Body>
</Accordion.Item>





<br />
<Accordion.Item eventKey="8">
<Accordion.Header> <b className='accord-head'>9. Vivo V29 Pro </b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
{/* <div className="accord-inbox"> */}

                  {/* Mobile Area */}


<h3 className='topic'><b>Mobile: Details</b> </h3>

<div className="mobiledetail">
<div className="mobilebox">
<li><b>Brand:</b> </li>
<li><b>Model:</b> </li>
<li><b>Color:</b> </li>
<li><b>Screen Size:</b> </li>
<li><b>Resolution:</b> </li>
<li><b>Display: </b></li>
<li><b>Camera: </b></li>
<li><b>Camera Modes:</b> </li>
</div>

<div className="mobilebox mbox2">
<li><b>Battery:</b> </li>
<li><b>Charging Type:</b> </li>
<li><b>Processor:</b></li>
<li><b>Operating System:</b></li>
<li><b>Android Version:</b> </li>
<li><b>Memory: </b></li>
<li><b>SIM Type:</b> </li>
<br />
<li><b>Launched Date: </b></li>
</div>

</div>




<div className="autobox">
<button className="buybotton"> <a href="">Check: Buy</a></button>
</div>
</div>
</Accordion.Body>
</Accordion.Item>




<br />
<Accordion.Item eventKey="9">
<Accordion.Header> <b className='accord-head'>10. Samsung Galaxy S22</b></Accordion.Header>
<Accordion.Body>
<div className="accord-box">
{/* <div className="accord-inbox"> */}


<Carousel data-bs-theme="dark">
{/* Slider 1 */}
<Carousel.Item>

<div className="carouselimage">
<a href="https://www.digitalarunachal.com/">
<img
// className="d-block w-100"
src="images/gumbaja.jpg"
alt="First slide"
/>
</a>

</div>

{/* <Carousel.Caption>
<h5>First slide label</h5>
<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
</Carousel.Caption> */}
</Carousel.Item>



{/* Slider 2 */}
<Carousel.Item>
<div className="carouselimage">
<Link to="/Jobs"> 
<img
src="images/gumbajam.png"
alt="Second slide"
/>
</Link>
</div>


{/* <Carousel.Caption>
<h5>Second slide label</h5>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
</Carousel.Caption> */}
</Carousel.Item>

{/* Slider 3 */}
<Carousel.Item>
<div className="carouselimage">
<img
src="images/gumbaja.jpg"
alt="Third slide"
/>
</div>
{/* <Carousel.Caption>
<h5>Third slide label</h5>
<p>
Praesent commodo cursus magna, vel scelerisque nisl consectetur.
</p>
</Carousel.Caption> */}
</Carousel.Item>
</Carousel>
<br />





                  {/* Mobile Area */}


<h3 className='topic'><b>Mobile: Details</b> </h3>

<div className="mobiledetail">
<div className="mobilebox">
<li><b>Brand:</b> </li>
<li><b>Model:</b> </li>
<li><b>Color:</b> </li>
<li><b>Screen Size:</b> </li>
<li><b>Resolution:</b> </li>
<li><b>Display: </b></li>
<li><b>Camera: </b></li>
<li><b>Camera Modes:</b> </li>
</div>

<div className="mobilebox mbox2">
<li><b>Battery:</b> </li>
<li><b>Charging Type:</b> </li>
<li><b>Processor:</b></li>
<li><b>Operating System:</b></li>
<li><b>Android Version:</b> </li>
<li><b>Memory: </b></li>
<li><b>SIM Type:</b> </li>
<br />
<li><b>Launched Date: </b></li>
</div>

</div>




<div className="autobox">
<button className="buybotton"> <a href="">Check: Buy</a></button>
</div>
</div>


</Accordion.Body>
</Accordion.Item>



</Accordion>
<br />


</div>


      <hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
          <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
        </ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Bestratedmobiles;


