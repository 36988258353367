import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton'
import Footer from '../pages/Footer';;

const English = () => {
  return (
<>
<Helmet>
        <title>Basic English Grammar by GumBaja</title>
        <meta name="description" content="Basic English Grammar" />
        <meta property="og:title" content="Social media title" />
        <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
      </Helmet>


<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Basic English </b></h2>

<hr className="separator" />

{/* Part 1 */}
<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'><b>Basic English Grammar</b> </h3>

  <ol>
  <li><Link className={"link-styles"} to="/Grammarthesentences">The Sentences </Link></li>
  <li><Link className={"link-styles"} to="/Grammarpartsofspeech">Parts of Speech </Link></li>

  <li><Link className={"link-styles"} to="/Grammarthenoun">The Noun: Kinds of Nounds </Link></li>
  <li><Link className={"link-styles"} to="/Grammarpronoun">Pronouns </Link></li>
  <li><Link className={"link-styles"} to="/Grammartheadjectives">The Adjectives and its uses</Link></li>
  <br />
 
  <li><Link className={"link-styles"} to="/Grammartheverb">The Verbs </Link></li>
  <li><Link className={"link-styles"} to="/Grammartheadverb">The Adverbs </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/Grammartenses">Tenses: Present, Fuuture and Past </Link></li>

 <br />
 <li><Link className={"link-styles"} to="/Grammararticle">Articles </Link></li>
  <li><Link className={"link-styles"} to="/Grammarthepreposition">The Preposition </Link></li>
  <li><Link className={"link-styles"} to="/Grammartheconjunction">The Conjunction </Link></li>
  <li><Link className={"link-styles"} to="/Grammarinterjection">The Interjection </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/Grammaractiveandpassivevoice">Voice: Active and Passive Voice </Link></li>
  <li><Link className={"link-styles"} to="/Grammardirectandindirectspeech">Speech: Direct and Indirect Speech </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'><b> English Words </b></h3>

<ol>
  <li><Link className={"link-styles"} to="/Grammarantonymsandsynonyms">English: Antonms and Synonyms </Link></li>
  <li><Link className={"link-styles"} to="/Grammaronewordsubstitution">One word Substitutions </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/Grammaridiomsandphrases">Idioms </Link></li>
  <li><Link className={"link-styles"} to="/Grammarrootwords">Root Words </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>

</div>
</div>
<hr className="separator" />


<WhatsAppShareButton/>




<hr className="separator" />

<div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/History">History </Link></li>
          <li><Link className={"link-styles"} to="/Geography">Geography </Link></li>
          <li><Link className={"link-styles"} to="/Psir">Polity </Link></li>
          <li><Link className={"link-styles"} to="/Economy">Economy </Link></li>
          <li><Link className={"link-styles"} to="/Environment">Environment </Link></li>
          <li><Link className={"link-styles"} to="/Science">Science and Technology </Link></li>
          <li><Link className={"link-styles"} to="/English">Basic English Grammar </Link></li>
        </ol>
      </div>




    <hr className="separator" />
    <br/>
  <Footer/>
  <Outlet />
</>
  )
}

export default English
