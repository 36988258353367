import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Whatsappchat from '../component/Whatsappchat';




// npm install react-helmet

function Ibpspoform2024({ }) {
  return (

    <>


      <Helmet>
        <title>IBPS PO Recruitment 2024</title>
        <meta name="description" content="NABARD Grade A Recruitment 2024" />
        <meta property="og:title" content="NABARD Grade A Recruitment 2024" />
        {/* <meta id="og-image" property="og:image" content="path/images/cat1.jpg" /> */}
      </Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>IBPS PO Recruitment 2024 | Vacacies </b></h3>

<hr className="separator" />


<div className="content-box">
        <br /> 
    
          <ul className='jobheadline'>
          <li><b>IBPS Probationary Officer / Management Trainee XIV Recruitment 2024 </b></li>
          <br />
          <li> <b>CRP PO/MT-XIV and CRP SPL-XIV COMMON RECRUITMENT PROCESS FOR RECRUITMENT OF PROBATIONARY OFFICERS/ MANAGEMENT TRAINEES (CRP PO/MT-XIV for Vacancies of 2025-26) AND RECRUITMENT OF SPECIALIST OFFICERS (CRP SPL-XIV for Vacancies of 2025-26) IN PARTICIPATING BANKS. </b></li>
          <br />
          <li><b>Total: 4455 Posts</b></li>
           
          </ul>
       
        
        <br />
        <hr className="separator" />
<Accordion>
<br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. Important Dates: .. </b> <h4 className="clickhere">  Click Here</h4> </Accordion.Header>
        <Accordion.Body>
          <div className="accord-box jobdetails">
          <ol>
              <li>Application Start: <b>01/08/2024</b> </li> <br />
              <li>Last Date: <b>28/08/202</b> 4</li> <br />
              
              <li>Prelims' Date: <b>October 2024</b> </li> <br />
              <li>Mains Date: <b>November 2024</b> </li> <br />
              <li>Interview Date: <b>Jan-Feb 2025</b> </li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. Vacancy Details: Eligibility  </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
      
              <li>Central Bank of India CBI : 1500+ Post</li> <br />
              <li>Bank Of India BOI : 885+ Post</li> <br />
              <li>Canara Bank : 750+ Post</li> <br />
              <li>Punjab & Sind Bank : 360+ Post</li> <br />
              <li>Indian Overseas Bank : 260+ Post</li> <br />
              <li>Punjab National Bank : 200+ Post</li> <br />
            </ol>

            <ul>
              <li><b>Eligibility:</b> Bachelor Degree in Any Stream from Any Recognized Board in India.</li> <br />
              <li><b> Age Limit (as on 01/08/2024):</b> Age: 20-30 Years</li>
            </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>4. Pay Scale (Salary) </b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
          <ul>
             <li><b>Monthly (Present):</b> The initial in-hand salary is INR 52,000 to 58,000.</li>
          </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>





      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>5. Job Profile </b></Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
        <ol>
              <li>Banking Services</li> <br />
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='accord-head'>6. Exam Details: Prelim / Mains / Interview </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li>Prelims (Page-13): <a href="https://www.ibps.in/wp-content/uploads/Detailed-Notification_CRP-PO-XIV_7.8.24.pdf">Click Here</a></li> <br />
              <li>Mains: <a href="https://www.ibps.in/wp-content/uploads/Detailed-Notification_CRP-PO-XIV_7.8.24.pdf">Click Here</a></li> <br />
              <li>Interview: <a href="https://www.ibps.in/wp-content/uploads/Detailed-Notification_CRP-PO-XIV_7.8.24.pdf">Click Here</a></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header><b className='accord-head'>7. Documents Required:</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
              <li>Photograph</li> <br />
              <li>Signature</li> <br />
              <li>Left thumb impression</li> <br />
              <li>A hand written declaration</li> <br />
        </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>8. Application Fee:</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
              <li><b>Gen / OBC / EWS: </b> 850/-</li> <br />
              <li><b>SC / ST / PH:</b> 175/-</li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>9. Important Links (Download / Apply): </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>
              <li>Download Notification: <a href="https://www.ibps.in/wp-content/uploads/Detailed-Notification_CRP-PO-XIV_7.8.24.pdf">Click Here</a></li> <br />
              <li>Apply: <a href="https://ibpsonline.ibps.in/crppo14jul24/">Click Here</a></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>10. Best Rated Books: Click Here</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li><a href="https://amzn.to/3XfdeNh">All Banks PO PYQs 2019-2023</a></li>
              <br />
              <li><a href="https://amzn.to/3Xj8eaz">IBPS PO Pre and Mains Solved Papers (2011-2023)</a></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> 


{/*       
      <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>11. Contact us: To Apply Online</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>
              <li>Chargeable </li> <br />
              <li>Call/WhatsApp: 07005369425  </li> <br />
              <Whatsappchat/>
              <br />
              <li>Join WhatsApp Group: <a href="https://chat.whatsapp.com/EHZC9jtiDtbCt3MsJwArNG">Click Here</a></li>
              <li>Follow WhatsApp Channel: <a href="https://whatsapp.com/channel/0029Val5Qj1EKyZFje1gXx21">Click Here </a></li>
              <br />
              <li>Telegram Channel: <a href="https://t.me/gumbaja29">Click here</a></li>
        </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


 



      
      {/* <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>11. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}





{/*       
      <br />
      <Accordion.Item eventKey="11">
        <Accordion.Header> <b className='accord-head'>12. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


      <br />

</Accordion>
</div>

      <hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
  <ol>

<li><Link className={"link-styles"} to="/Irdaiassistantmanagerform2024">IRDAI Assistant Manager Form 2024 | 49 Posts</Link></li>
<br />
<li><Link className={"link-styles"} to="/Nainitalbankpoandotherposts">Nainital Bank PO and Other Posts</Link></li>
</ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Ibpspoform2024;


