import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
import Footer from '../pages/Footer';
import Accordion from 'react-bootstrap/Accordion';


const Environment = () => {
  return (
<>
<Helmet>
  <title>Environment by GumBaja</title>
  <meta name="description" content="Environment" />
  <meta property="og:title" content="Social media title" />
  <meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>



<br/>
    <hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}> <b>Environment and Ecology</b> </h2>

<hr className="separator" />
                       {/*  Par 1*/}
                       <div className="Mainbox1">
<div className="sbox1">

<div>
  <Accordion>
    <br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h3 className='recomm'>Books: Topper's Choice</h3></Accordion.Header>
        <Accordion.Body>
          <div className="accord-box">
          <ol>
              <li>Environment: <a href="https://amzn.to/3MACeIR">Shankar IAS Academy</a></li>
          </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
  </Accordion>
</div>



  <h3 className='topic'>Basic of Environment and Ecology</h3>
  <ol>
  <li><Link className={"link-styles"} to="">Terms: Definitions </Link></li>
  <li><Link className={"link-styles"} to="">Basics of Ecology </Link></li>
  <li><Link className={"link-styles"} to="">Functions of an Ecosytem </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Terrestrial Ecosystem </Link></li>
  <li><Link className={"link-styles"} to="">Aquatic Ecosystem </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Biodiversity & Conservation</h3>
<ol>
  <li><Link className={"link-styles"} to="">Basics of Biodiversity </Link></li>
  <li><Link className={"link-styles"} to="">Important Conference, Convention and Summit on Environment </Link></li>
  <li><Link className={"link-styles"} to="">Types of Protected Areas </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Conservation Efforts and Campaign </Link></li>
  <li><Link className={"link-styles"} to="">Environmental Organisation and Acts (Laws): World </Link></li>
  <li><Link className={"link-styles"} to="">Environmental Organisation and Acts (Laws): India </Link></li>
</ol>

</div>
</div>
<hr className="separator" />



{/* Part 2 */}
<div className="Mainbox1">
<div className="sbox1">
  <h3 className='topic'>Climate Change | Agriculture</h3>
  <ol>
  <li><Link className={"link-styles"} to="">What is Cliamte Change and Its Impact? </Link></li>
  <li><Link className={"link-styles"} to="">Strategies to Mitigate Climate Changes </Link></li>
  <li><Link className={"link-styles"} to="">Climate Change Organisation: India and World </Link></li>
 
  <li><Link className={"link-styles"} to="">India and Climate Change </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Agriculture </Link></li>
  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Environmental Pollution and Degradation</h3>

<ol>
  <li><Link className={"link-styles"} to="">What is an Environment Pollution and Its Impact? </Link></li>
  <li><Link className={"link-styles"} to="">Envrinmental Issues </Link></li>
  <li><Link className={"link-styles"} to="">Environment Impact Assessment </Link></li>
  <br />
  <li><Link className={"link-styles"} to="">Important Committee on Environment and Their Recommendations </Link></li>
  
  {/* <li><Link className={"link-styles"} to="/Dummypost">Format </Link></li> */}

  {/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
  <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>




</div>
</div>
<hr className="separator" />


<WhatsAppShareButton/>




<hr className="separator" />

<div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
          <li><Link className={"link-styles"} to="/History">History </Link></li>
          <li><Link className={"link-styles"} to="/Geography">Geography </Link></li>
          <li><Link className={"link-styles"} to="/Psir">Polity </Link></li>
          <li><Link className={"link-styles"} to="/Economy">Economy </Link></li>
          <li><Link className={"link-styles"} to="/Environment">Environment </Link></li>
          <li><Link className={"link-styles"} to="/Science">Science and Technology </Link></li>
          <li><Link className={"link-styles"} to="/English">Basic English Grammar </Link></li>
        </ol>
      </div>




    <hr className="separator" />
    <br/>
  <Footer/>
  <Outlet />
</>
  )
}

export default Environment
