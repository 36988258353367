import React from 'react'
import Footer from './Footer'

const Terms = () => {
  return (
    <>
      
      <h1 style={{
      textAlign: 'center',
      // border: 'solid 2px black',
}
 
}>  Terms of Services </h1>

      <div className="content-box">
      Welcome to www.gumbaja.com . These Terms of Service govern your use of our website. By accessing and using www.gumbaja.com, you agree to comply with and be bound by these terms. If you do not agree to these terms, please do not use our Website.

      <ol>
        <li>Use of the Website:</li> 
        1. You must be at least 18 years old to use this Website. <br />
        2. You are responsible for ensuring the security of your account and for all activities conducted under your account. <br />
        3. You agree not to use the Website for any unlawful or prohibited purpose.
        <br /> <br />
        
        <li>User Content:</li>
        1. Users may submit content, including but not limited to reviews, comments, and images. <br />
        2. By submitting content, you grant www.gumbaja.com a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.
        <br />
        <br />

        <li>Intellectual Property:</li>
        1. All content on the Website, including text, graphics, logos, images, and software, is the property of www.gumbaja.com and is protected by intellectual property laws. <br />
        2. You may not use, reproduce, modify, distribute, or publicly display any content from the Website without the prior written consent of www.gumbaja.com.
        <br /> <br />

        <li>Privacy:</li>  By using the Website, you consent to the collection and use of your personal information as outlined in our Privacy Policy.
        <br /><br />

        <li>Termination:</li>  www.gumbaja.com reserves the right to terminate or suspend your access to the Website without prior notice for any violation of these terms.
        <br /><br />

        <li>Limitation of Liability:</li> www.gumbaja.com is not liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use the Website. <br /><br />

        <li>Changes to Terms:</li>  www.gumbaja.com may revise these terms at any time. The latest version will be posted on this page with the effective date. <br /><br />

        <li>Governing Law: </li> These terms are governed by and construed in accordance with the laws of [your jurisdiction]. <br /><br />

        <li> Contact Us:</li> If you have any questions or concerns about these Terms of Service, please contact us. <br />
      
      </ol>
    </div>
    
<br />
<br />

      <hr className="separator"/>
      <br />
      <h1 style={{
      textAlign: 'center',
      // border: 'solid 2px black',
}
 
}>  Privacy Policy </h1>


<div className="content-box">
At www.gumbaja.com, we are committed to protecting your privacy and safeguarding your personal information. This Privacy Policy explains how we collect, use, and disclose your personal information when you visit our Website. <br /> <br />

<ol>
  <li><b>Information We Collect:</b></li>
<b>1. Personal Information:</b> When you register for an account, we may collect your name, email address, and other necessary information. If you make a purchase, we may collect payment information, billing, and shipping details. 
<br />
<b>2. Non-Personal Information:</b> We may collect non-personal information such as browser type, operating system, and IP address for analytics purposes.
<br /> <br />

<li><b>How We Use Your Information:</b></li> 
<b>1. Personal Information:</b> We use your personal information to provide the services you request, such as processing orders and customer support. With your consent, we may send you promotional emails about our products and services. <br />

<b>2. Non-Personal Information:</b> We use non-personal information for statistical analysis, to improve our Website, and for marketing purposes.
<br />
<br />

<li><b>How We Protect Your Information:</b></li>
We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.
We use secure socket layer (SSL) technology to encrypt sensitive information during transmission.

<br /> <br />

<li><b>Cookies:</b></li> We use cookies to enhance your user experience. You may choose to disable cookies in your browser settings, but some features of the Website may not function properly as a result.
<br />
<br />

<li><b>Third-Party Links:</b></li>
 Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. Please review the privacy policies of third-party sites before providing any personal information. <br /> <br />

<li><b>Your Choices:</b></li>
You have the right to:
Access, correct, or delete your personal information. Opt-out of receiving promotional emails. <br />

<br />
<li><b>Changes to This Privacy Policy:</b></li>
 We may update our Privacy Policy from time to time. The latest version will be posted on this page with the effective date.

<br /><br />
<li><b>Contact Us:</b></li>
 If you have any questions or concerns about our Privacy Policy, please contact us.
</ol>
      </div>
    
    <Footer/>
    </>

  )
}

export default Terms
