import React from 'react'
import Footer from './Footer'
import { Outlet, Link } from "react-router-dom";
// import Mainslider from "../component/Mainslider";
import WhatsAppShareButton from '../component/WhatsAppShareButton';
import Gumbajaslider from '../slider/Gumbajaslider';


const Home = () => {
  return (
<>

{/* <Mainslider/> */}
<hr className="separator"/>


      {/* <ImageSlider /> */}
      <Gumbajaslider/>


<hr className="separator"/> 
<h1 className="latest-post"><Link className={"link-styles"} to="/Latestposts"><b> Latest-posts </b> </Link></h1>
<hr className="separator"/>

<div className="Mainbox1">
                            {/* Box */}
<div className="sbox1">
 <h3 className='topic'>Study Notes</h3>

<ol>
<li><Link className={"link-styles"} to="/Iastoppersandsyllabus">CSE (IAS) Toppers and Syllabus </Link></li>
  <li><Link className={"link-styles"} to="/History">History Notes</Link> </li>
  <br />
  <li><Link className={"link-styles"} to="/Geography">Geography Notes</Link></li>
 
  <li><Link className={"link-styles"} to="/Psir">PSIR Notes</Link></li>
  <br />
  <li><Link className={"link-styles"} to="/Economy">Economy Notes</Link></li>
  
  <li><Link className={"link-styles"} to="/Environment">Environment and Ecology Notes</Link></li>
  <br />
  <li><Link className={"link-styles"} to="/Science">Science and Technology Notes</Link></li>
  
  <li><Link className={"link-styles"} to="/Security">Security and Disaster Management Notes</Link></li>

  <li><Link className={"link-styles"} to="/">Ethics </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/English">Basic English Notes</Link></li>

  <li><Link className={"link-styles"} to="/">General Knowledge </Link></li>
  {/* <li><Link className={"link-styles"} to="/"> </Link></li> */}
</ol>

  <div className="btn-group">
  <button><Link className={"link-styles"} to="/Iastoppersandsyllabus">More </Link> </button>
</div>
</div>




<div className="sbox1 part2">
  <h3 className='topic'>Learnings: Book Summary</h3>
  <ol>
  <li><Link className={"link-styles"} to="/The7habitsofhighlyeffectivepeoplebystephenrcovey">The 7 Habits of Highly Effective People by Stephen R. Covey.  </Link></li>
  <li><Link className={"link-styles"} to="/Youcanwinbyshivkhera">You Can Win by Shiv Khera </Link></li> 
  <br />
  <li><Link className={"link-styles"} to="/Thinkandgrowrichbynapoleonhill">Think and Grow Rich by Napoleon Hill</Link></li>

  <li><Link className={"link-styles"} to="/Richdadpoordadbyrobertkiyosaki">Rich Dad Poor Dad by Robert T. Kiyosaki    </Link></li>


  <li><Link className={"link-styles"} to="/Therichestmaninbabylonbygeorgesclason">The Richest Man in Babylon by George S. Clason  </Link></li>

  <li><Link className={"link-styles"} to="/Ikigaibybyfrancescmirallesandhectorgarcia">Ikigai by by Francesc Miralles and Hector Garcia </Link></li>


  
  <br />
  <h3 className='topic' style={{color: "green", fontSize: ""}}>Coming Soon</h3>
<br />




 
  <li><Link className={"link-styles"} to="/">The Psychology of Money by Morgan Housel </Link></li>

  {/* <li><Link className={"link-styles"} to="/">The Intelligent Investors by Benjamin Graham</Link></li>

  <br />
  <li><Link className={"link-styles"} to="/">The Compound Effect by Darren Hardy </Link></li>

  <li><Link className={"link-styles"} to="/">The Alchemist by Paulo Coelho </Link> </li>

  <br />


  <li><Link className={"link-styles"} to="/">How to Win Friends and Influence People by Dale Carnegie </Link></li> */}

  {/* <li><Link className={"link-styles"} to="/"> </Link></li> */}
</ol>


<br />

<div className="btn-group">
<button><Link className={"link-styles"} to="/Learnings">More </Link> </button>
</div>

</div>
</div>



<hr className="separator" />


                           {/* Second Part */}

<div className="Mainbox1">
<div className="sbox1">
<h3 className='topic'>Reviews</h3>
 
<ol>
<li><Link className={"link-styles"} to="/Bestratedearbuds">Best Rated Earbuds </Link></li>

<br />
  <h3 className='topic' style={{color: "green", fontSize: "18px"}}>Coming Soon</h3>
<br />

  <li><Link className={"link-styles"} to="/Bestratedmobiles">Best Rated Mobiles</Link></li>
  <li><Link className={"link-styles"} to="">Best Rated Tabs</Link></li>

  {/* <li><Link className={"link-styles"} to="/">Best Rated Headphones / Earphones </Link></li>
  
  <li><Link className={"link-styles"} to="/">Best Rated Bluetooth Speakers </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/">Best Rated Laptops</Link></li>
  <li><Link className={"link-styles"} to="/">Best Rated Computer Accessories </Link></li>
  <br />
  <li><Link className={"link-styles"} to="/">Best Rated Smart Tvs</Link></li>
  <li><Link className={"link-styles"} to="/">Best Rated Gadgets</Link></li> */}
  {/* <li><Link className={"link-styles"} to="/"> </Link></li> */}
</ol>


  <div className="btn-group">
  <button><Link className={"link-styles"} to="/Review">More </Link> </button>
</div>
</div>




                              {/* Latest Jobs: Job Updates */}

<div className="sbox1 part2">
<h3 className='topic'>Latest Jobs Updates</h3>

<ol>
<li><Link className={"link-styles"} to="/Irdaiassistantmanagerform2024">IRDAI Assistant Manager Form 2024 (49 Posts)</Link></li>

<br />
<li><Link className={"link-styles"} to="/Ibpspoform2024">IBPS PO Form 2024 (4455 Posts) </Link></li>
<li><Link className={"link-styles"} to="/Nainitalbankpoandotherposts">Nainital Bank PO and Others (25 Posts)</Link></li>

  {/* <li><Link className={"link-styles"} to="/"> </Link></li> */}
  {/* <li><Link className={"link-styles"} to="/"> </Link></li>
  <li><Link className={"link-styles"} to="/"> </Link></li> */}
</ol>

<div className="btn-group">
<button><Link className={"link-styles"} to="/Jobs">More </Link> </button>
</div>

</div>
</div>


<hr className="separator"/>

<WhatsAppShareButton/>

<hr className="separator"/>

<Footer/>
<Outlet />
</>
  )
}

export default Home
