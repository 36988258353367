import React from 'react';
import { Outlet, Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
import Accordion from 'react-bootstrap/Accordion';


import Footer from '../pages/Footer';

const Psir = () => {
return (
<>
<Helmet>
<title>PSIR by GumBaja</title>
<meta name="description" content="PSIR" />
<meta property="og:title" content="Social media title" />
<meta id="og-image" property="og:image" content="path/images/cat1.jpg" />
</Helmet>





<br/>
<hr className="separator"/>
<h2 className="latest-post" style={{color: "green"}}>  <b>PSIR</b> </h2>

<hr className="separator" /> 

<div className="Mainbox1">
<div className="sbox1">
<h3 className='topic'>Political Theory</h3>
<ol>
<li><Link className={"link-styles"} to="">What is Political Theory?</Link></li>
<li><Link className={"link-styles"} to="">Theories of States </Link></li>
<li><Link className={"link-styles"} to="">Concepts of (Justice, Equality, Rights, Power.) </Link></li>

<br />
<li><Link className={"link-styles"} to="">Political Ideologies </Link></li>
<li><Link className={"link-styles"} to="">Western Political Thought </Link></li>
<li><Link className={"link-styles"} to="">Indian Political Thought </Link></li>
</ol>

<div>
<Accordion>
<br />
<Accordion.Item eventKey="0">
<Accordion.Header> <h3 className='recomm'>Books:</h3>  - (Click Here)</Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ol>
<h3 className='recomm'>Toppers' Recommended:</h3>
<li>Political Theory: <a href="https://amzn.to/3WYfcA8">Click Here</a></li> 
<li>Political Thinkers (Western): <a href="https://amzn.to/3YU7c5T">Click Here</a></li> <br />
<li>Political Thinkers (Indian): <a href="https://amzn.to/3MhnFK4">Click Here</a></li>
</ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
</Accordion>
</div>


</div>




<div className="sbox1 part2">
<h3 className='topic'>Indian Constitution</h3>
<ol>
<li><Link className={"link-styles"} to="">Indian Constitution: Parts and Schedules (PSIR) </Link></li>
<br />
<li><Link className={"link-styles"} to="">Historical Background of Indian Constitution </Link></li>
<li><Link className={"link-styles"} to="">Sources Of Indian Constitution </Link></li>
<br />




<br />

<li><Link className={"link-styles"} to="">Salient Feautures of the Indian Constitution </Link></li>
<li><Link className={"link-styles"} to="">Comparison of Indian Constitution with other Democratic Countries </Link></li>

<br />
<li><Link className={"link-styles"} to="">Landmark Cases on Indian Constitution </Link></li>
<li><Link className={"link-styles"} to="">Important Amendments of Indian Constitution </Link></li>
<li><Link className={"link-styles"} to="">Constitutional Bodies </Link></li>
{/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>



<div>
<Accordion>
<br />
<Accordion.Item eventKey="0">
<Accordion.Header> <h3 className='recomm'>Books:</h3>  - (Click Here)</Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ol>
<h3 className='recomm'>Toppers' Recommended:</h3>
<li>Indian Constitution: <a href="https://amzn.to/4dN3ccd">PM Bakshi</a></li> <br />
</ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
</Accordion>
</div>
</div>
</div>
<hr className="separator" />




{/* Part 2 */}

<div className="Mainbox1">
<div className="sbox1">
<h3 className='topic'>Indian Polity & Govt.</h3>
<ol>
<li><Link className={"link-styles"} to="">Parliament of India </Link></li>
<li><Link className={"link-styles"} to="">Parliamentary vs Presidential Sytem </Link></li>
<li><Link className={"link-styles"} to="">State Legislature </Link></li>


<br />
<li><Link className={"link-styles"} to="">Separation of Power: Legislature, Executive and Judiciary </Link></li>
<li><Link className={"link-styles"} to="">Supreme Court and Its Important Judgements </Link></li>
<li><Link className={"link-styles"} to="">Federalism: Decentralisation of Powers </Link></li>


<br />
<li><Link className={"link-styles"} to="">Salient features of the Representation of People’s Act </Link></li>
<li><Link className={"link-styles"} to="">Centre-State relations </Link></li>
<li><Link className={"link-styles"} to="">Union Territories </Link></li>


<br />
<li><Link className={"link-styles"} to="">Local Self-Government </Link></li>
<li><Link className={"link-styles"} to="">Scheduled and Tribal Areas </Link></li>
<li><Link className={"link-styles"} to="">Dispute Resolution Mechanism </Link></li>


<br />
{/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
<li><Link className={"link-styles"} to="">Coming Soon </Link></li>
<li><Link className={"link-styles"} to="">Coming Soon </Link></li>


<br />
<li><Link className={"link-styles"} to="">Coming Soon </Link></li>
<li><Link className={"link-styles"} to="">Coming Soon </Link></li>
<li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>



<div>
<Accordion>
<br />
<Accordion.Item eventKey="0">
<Accordion.Header> <h3 className='recomm'>Books:</h3>  - (Click Here)</Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ol>
<h3 className='recomm'>Toppers' Recommended:</h3>
<li>Indian Polity: <a href="https://amzn.to/4dx7qEA">Lakshmikant</a></li> <br />
</ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
</Accordion>
</div>
</div>




<div className="sbox1 part2">
<h3 className='topic'>Governance</h3>
<ol>
<li><Link className={"link-styles"} to="">Role of Civil Services in Democracy </Link></li>
<li><Link className={"link-styles"} to="">Transparency and Accountability </Link></li>
<li><Link className={"link-styles"} to="">E-Governance in India </Link></li>


<br />
<li><Link className={"link-styles"} to="">Development Process and Industry </Link></li>
<li><Link className={"link-styles"} to="">Welfare Scemes and Constitutional Provisions </Link></li>
<li><Link className={"link-styles"} to="">Social Sector Issues: Health, Education and Employment </Link></li>


<br />
<li><Link className={"link-styles"} to="">Poverty, Inequality and Hunger related Issues </Link></li>
{/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
<li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>



<div>
<Accordion>
<br />
<Accordion.Item eventKey="0">
<Accordion.Header> <h3 className='recomm'>Books:</h3>  - (Click Here)</Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ol>
<h3 className='recomm'>Toppers' Recommended:</h3>
<li>Governance: <a href="https://amzn.to/4dCYL3p">Click Here</a></li> <br />
</ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
</Accordion>
</div>

</div>
</div>
<hr className="separator" />



{/* Part 3 */}
<div className="Mainbox1">
<div className="sbox1">
<h3 className='topic'>Important Polity Topics</h3>
<ol>
<li><Link className={"link-styles"} to="">RPA </Link></li>
<li><Link className={"link-styles"} to="">Anti-Defaction Law </Link></li>
<li><Link className={"link-styles"} to="">Pressure Groups, etc.. </Link></li>


<br />
<li><Link className={"link-styles"} to="">Non-Constitutional Bodies </Link></li>
<li><Link className={"link-styles"} to="">Government Schemes </Link></li>
<br />
<li><Link className={"link-styles"} to="">President of India </Link></li>
<li><Link className={"link-styles"} to="">Prime Minister of India: Inidia's Journey </Link></li>
<li><Link className={"link-styles"} to="">Chief Justice of India </Link></li>
{/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
</ol>



<div>
<Accordion>
<br />
<Accordion.Item eventKey="0">
<Accordion.Header> <h3 className='recomm'>Books:</h3>  - (Click Here)</Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ol>
<h3 className='recomm'>Toppers' Recommended:</h3>
<li>Indian Polity: <a href="https://amzn.to/3AAfDcq">Lakshmikant</a> </li> <br />
</ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
</Accordion>
</div>
</div>




<div className="sbox1 part2">
<h3 className='topic'>IR</h3>

<ol>
<li><Link className={"link-styles"} to="">International Relations </Link></li>
<li><Link className={"link-styles"} to="">Comparative Politics </Link></li>
<li><Link className={"link-styles"} to="">Important Thinkers of IR </Link></li>

<br />
<li><Link className={"link-styles"} to="">India and Its Neghbours </Link></li>
<li><Link className={"link-styles"} to="">India and its Bilateral Relations with Developing Countries </Link></li>
<li><Link className={"link-styles"} to="">India and its Bilateral Relations with Developed Countries </Link></li>


<br />
<li><Link className={"link-styles"} to="">India and International Organisations </Link></li>
<li><Link className={"link-styles"} to="">India: Regional and Global Groups </Link></li>
<li><Link className={"link-styles"} to="">Indian Diaspora </Link></li>
<li><Link className={"link-styles"} to="">Other Important Topics </Link></li>
{/* <li><Link className={"link-styles"} to="">Coming Soon </Link></li>
<li><Link className={"link-styles"} to="">Coming Soon </Link></li> */}
{/* <li><Link className={"link-styles"} to="/Dummypost">Format </Link></li> */}
</ol>



<div>
<Accordion>
<br />
<Accordion.Item eventKey="0">
<Accordion.Header> <h3 className='recomm'>Books:</h3>  - (Click Here)</Accordion.Header>
<Accordion.Body>
<div className="accord-box">
<ol>
<h3 className='recomm'>Toppers' Recommended:</h3>
<li>IR Book: <a href="https://amzn.to/3SYbRQD">Click Here</a></li> <br />
<li>Comparative Politics: <a href="https://amzn.to/3SZOZ3f">Click Here</a></li>
</ol>
</div>
</Accordion.Body>
</Accordion.Item>
<br />
</Accordion>
</div>
</div>
</div>
<hr className="separator" />

<WhatsAppShareButton/>





<hr className="separator" />

<div className="relatedposts">
<h3>Related Posts</h3>
<ol>
<li><Link className={"link-styles"} to="/History">History </Link></li>
<li><Link className={"link-styles"} to="/Geography">Geography </Link></li>
<li><Link className={"link-styles"} to="/Psir">Polity </Link></li>
<li><Link className={"link-styles"} to="/Economy">Economy </Link></li>
<li><Link className={"link-styles"} to="/Environment">Environment </Link></li>
<li><Link className={"link-styles"} to="/Science">Science and Technology </Link></li>
<li><Link className={"link-styles"} to="/English">Basic English Grammar </Link></li>
</ol>
</div>



<hr className="separator" />
<br/>
<Footer/>
<Outlet />
</>
)
}

export default Psir
