

import React from 'react';
import { Outlet, Link } from "react-router-dom";
import Footer from '../pages/Footer'
import { Helmet } from 'react-helmet';
import WhatsAppShareButton from '../component/WhatsAppShareButton';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Whatsappchat from '../component/Whatsappchat';




// npm install react-helmet

function Irdaiassistantmanagerform2024({ }) {
  return (

    <>


      <Helmet>
        <title>IRDAI Assistant Manager Recruitment 2024</title>
        <meta name="description" content="IRDAI Assistant Manager Recruitment 2024- total: 49 post" />
        <meta property="og:title" content="IRDAI Assistant Manager Recruitment 2024- total: 49 post" />
        {/* <meta id="og-image" property="og:image" content="path/images/cat1.jpg" /> */}
      </Helmet>


<hr className="separator"/>

<h3 className="latest-post" style={{color: "green"}}> <b>Insurance Regulatory and Development Authority of India (IRDAI) </b></h3>

<hr className="separator" />


<div className="content-box">
        <br /> 
    
          <ul className='jobheadline'>
          <li><b>IRDAI Assistant Manager Recruitment 2024 </b></li>
          <br />
          <li><b>Total: 49 Posts</b></li>
           
          </ul>
       
        <hr className="separator" />
<Accordion>
<br />
      <Accordion.Item eventKey="0">
        <Accordion.Header> <b className='accord-head'> 1. Important Dates: .. </b> <h4 className="clickhere">  Click Here</h4> </Accordion.Header>
        <Accordion.Body>
          <div className="accord-box jobdetails">
          <ol>
              <li>Application Start: <b>21/08/2024</b> </li> <br />
              <li>Last Date: <b>20/09/2024</b></li> <br />
              
              <li>Prelims' Date: <b>Will be informed in
              due course.</b> </li> <br />
              <li>Mains Date: <b>Coming Soon</b> </li> <br />
              <li>Interview Date: <b>Coming Soon</b> </li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <br />


      <Accordion.Item eventKey="1">
        <Accordion.Header> <b className='accord-head'>2. Vacancy Details: Eligibility  </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
      
              <li><b>General: 24 Post- </b>(Graduation with minimum 60% marks
              ).</li> <br />
              <li><b>Law: 5 Post- </b>(Bachelor Degree in Law (LLB) with Minimum 60% Marks).</li> <br />
              <li><b>Actuarial: 5 Post- </b>(Bachelor Degree in Any Stream with Minimum 60% Marks and 7 papers passed of IAI as per 2019 curriculum).</li> <br />
              <li><b>Finance: 5 Post- </b>(Bachelor Degree with Minimum 60% Marks ACA/AICWA/ACMA/ACS/CFA Degree).</li> <br />
              <li><b>Information Technology IT: 5Post- </b>(Bachelor Degree in Any Stream with PG Degree in Computer / IT with 60% Marks OR MCA OR BE / B.Tech Degree in Electrical / Electronics / Electronics and Communication / Information Technology / Computer Science/ Software Engineering with minimum 60% marks).</li> <br />
              <li><b>Research: 5 Post-</b> (Master Degree OR 2 Year Diploma in Economics / Statistics with Minimum 60% Marks.)</li> <br />
            </ol>

            <ul>
              {/* <li><b>Eligibility:</b> Bachelor Degree in Any Stream from Any Recognized Board in India.</li> <br /> */}
              <li><b> Age Limit (as on 20/09/2024):</b> Age: 21-30 Years</li>
            </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="3">
        <Accordion.Header><b className='accord-head'>4. Pay Scale (Salary) </b> </Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
          <ul>
             <li><b>Monthly (Present):</b> The initial in-hand salary is INR Rs. 1,46,000/ (+) .</li>
          </ul>
          </div>
        </Accordion.Body>
      </Accordion.Item>





      
      <br />
      <Accordion.Item eventKey="8">
        <Accordion.Header> <b className='accord-head'>5. Job Profile </b></Accordion.Header>
        <Accordion.Body>

        <div className="accord-box">
        <ol>
              <li>Regulatory Works in the Insurance Sectors </li> <br />
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="4">
        <Accordion.Header><b className='accord-head'>6. Exam Details: Prelim / Mains / Interview </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li>Prelims (Page: 3-4): <a href="https://irdai.gov.in/document-detail?documentId=5481330">Click Here</a></li> <br />
              <li>Mains: <a href="https://irdai.gov.in/document-detail?documentId=5481330">Click Here</a></li> <br />
              <li>Interview: <a href="https://irdai.gov.in/document-detail?documentId=5481330">Click Here</a></li>

              <li>Exam Centres: (Check Page No. 9)</li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>

      <br />
      <Accordion.Item eventKey="5">
        <Accordion.Header><b className='accord-head'>7. Documents Required:</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
              <li>Photograph</li> <br />
              <li>Signature</li> <br />
              <li>Left thumb impression</li> <br />
              <li>A hand written declaration (Page No. 23)</li> <br />
        </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      <br />
      <Accordion.Item eventKey="6">
        <Accordion.Header> <b className='accord-head'>8. Application Fee:</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box jobdetails">
        <ol>
              <li><b>Gen / OBC / EWS: </b> Rs.750/- (+ Examination fee and Intimation charges).</li> <br />
              <li><b>SC / ST / PH:</b> Rs.100/- (+ Intimation charges
              ).</li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>


      
      <br />
      <Accordion.Item eventKey="7">
        <Accordion.Header> <b className='accord-head'>9. Important Links (Download / Apply): </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>
              <li>Download Notification: <a href="https://irdai.gov.in/document-detail?documentId=5481330">Click Here</a></li> <br />
              <li>Apply: <a href="https://ibpsonline.ibps.in/irdaijun24/">Click Here</a></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>




      


      <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>10. Best Rated Books: Click Here</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            <ol>
              <li><a href="https://amzn.to/3yPWbrK">IRDAI (Assistant Managers) Books</a></li>
            </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item> 


      <br />
      <Accordion.Item eventKey="9">
        <Accordion.Header> <b className='accord-head'>11. Contact us: To Apply Online</b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
        <ol>
             <li>Chargeable </li> <br />
              <li>Call/WhatsApp: 07005369425  </li> <br />
              <Whatsappchat/>
              <br />
              <li>Join WhatsApp Group: <a href="https://chat.whatsapp.com/EHZC9jtiDtbCt3MsJwArNG">Click Here</a></li>
              <li>Follow WhatsApp Channel: <a href="https://whatsapp.com/channel/0029Val5Qj1EKyZFje1gXx21">Click Here </a></li>
              <br />
              <li>Telegram Channel: <a href="https://t.me/gumbaja29">Click here</a></li>
        </ol>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      
      {/* <br />
      <Accordion.Item eventKey="10">
        <Accordion.Header> <b className='accord-head'>11. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}





{/*       
      <br />
      <Accordion.Item eventKey="11">
        <Accordion.Header> <b className='accord-head'>12. </b></Accordion.Header>
        <Accordion.Body>
        <div className="accord-box">
            .
          </div>
        </Accordion.Body>
      </Accordion.Item> */}


      <br />

</Accordion>
</div>

      <hr className="separator" />

      <WhatsAppShareButton />

      <hr className="separator" />


      <div className="relatedposts">
        <h3>Related Posts</h3>
        <ol>
        <li><Link className={"link-styles"} to="/Ibpspoform2024">IBPS PO Form 2024 </Link></li>
        <li><Link className={"link-styles"} to="/Nainitalbankpoandotherposts">Nainital Bank PO and Other Posts</Link></li>
        </ol>
      </div>

      <br />


      {/* <h1 style={{
        textAlign: 'center',
        border: 'solid 2px black',
      }}> Dummy Contents</h1> */}

      <Footer />

      <Outlet />
    </>



  );
}
export default Irdaiassistantmanagerform2024;


