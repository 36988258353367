import React from 'react'
import Footer from './Footer'

const Disclaimer = () => {
  return (

    <>
   <h1 style={{
        textAlign: 'center',
        // border: 'solid 2px black',
}
 
}> Disclaimer for www.gumbaja.com </h1>

    <div className="content-box">
    The information provided on www.gumbaja.com is for general informational purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. <br />
In no event will we be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of www.gumbaja.com. <br />
Through www.gumbaja.com, you can link to other websites that are not under the control of www.gumbaja.com. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
Every effort is made to keep www.gumbaja.com up and running smoothly. <br />
However, www.gumbaja.com takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.



    </div>
    <br />
    <br />
    <hr className="separator"/>
<br />
<br />

    <h1 style={{
        textAlign: 'center',
        // border: 'solid 2px black',
}
 
}>  Affiliate Disclosure for www.gumbaja.com</h1>

    <div className="content-box">

www.gumbaja.com participates in various affiliate marketing programs, which means we may get paid commissions on purchases made through our links to retailer sites. <br />
Our editorial content is not influenced by advertisers or affiliate partnerships. This disclosure is provided in accordance with the Federal Trade Commission’s 16 CFR § 255.5: Guides Concerning the Use of Endorsements and Testimonials in Advertising.

We write review for products or Articles which contains Affiliate Links. <br />
As an Amazon Associate, www.gumbaja.com earns from qualifying purchases.
Please note that this affiliate disclosure is subject to change without notice. It is your responsibility to review this page regularly for updates. <br />
If you have any questions regarding our affiliate disclosure, please contact us.


    </div>

    <Footer/>
    </>

  )
}

export default Disclaimer
